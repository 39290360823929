import { apiSlice } from "store/api";
// GET /partner
// GET /partner/allBookings
// POST /partner/:id/update-status (id = partnerId, body = { status: string })
export const partnershipApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      partners: builder.query({
        query: () => "partner",
        providesTags: ["partners"],
      }),
      allBookings: builder.query({
        query: () => "partner/allBookings",
        providesTags: ["bookings"],
      }),
      updateStatus: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/partner/${id}/update-status`,
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["partners", "bookings"],
      }),
      deletePartner: builder.mutation({
        query: (id) => ({
          url: `/partner/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["partners"],
      }),
    };
  },
});

export const {
  usePartnersQuery,
  useAllBookingsQuery,
  useUpdateStatusMutation,
  useDeletePartnerMutation,
} = partnershipApi;
