// import { leftPad } from "./numberUtils";

// export const convertDate = (date, type) => {
//   if (!date) return "- - - -";
//   const d = new Date(date ? date : "");
//   const day = leftPad(d.getDate().toString(), 2, "0");
//   const month = leftPad(`${d.getMonth() + 1}`, 2, "0");
//   const year = leftPad(d.getFullYear().toString(), 2, "0");
//   const hours = leftPad(d.getHours().toString(), 2, "0");
//   const minutes = leftPad(d.getMinutes().toString(), 2, "0");

//   return type
//     ? type === "time"
//       ? `${hours}:${minutes}`
//       : `${day}-${month}-${year}`
//     : `${day}-${month}-${year} | ${hours}:${minutes}`;
// };

// import { leftPad } from "./numberUtils";

// const parseDateString = (dateString) => {
//   console.log('Parsing dateString:', dateString); // Debugging
//   const parts = dateString.split('/');
//   if (parts.length !== 3) return null;

//   const day = parseInt(parts[0], 10);
//   const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
//   const year = parseInt(parts[2], 10);

//   return new Date(year, month, day);
// };

// export const convertDate = (date, type) => {
//   if (!date) return "- - - -";

//   const d = parseDateString(date);
//   if (!d || isNaN(d.getTime())) return "Invalid Date";

//   const day = leftPad(d.getDate().toString(), 2, "0");
//   const month = leftPad((d.getMonth() + 1).toString(), 2, "0");
//   const year = leftPad(d.getFullYear().toString(), 4, "0");
//   const hours = leftPad(d.getHours().toString(), 2, "0");
//   const minutes = leftPad(d.getMinutes().toString(), 2, "0");

//   if (type === "number") {
//     return `${year}-${month}-${day}`;
//   }

//   return type
//     ? type === "time"
//       ? `${hours}:${minutes}`
//       : `${day}-${month}-${year}`
//     : `${day}-${month}-${year} | ${hours}:${minutes}`;
// };

// +++++++++++++++++++++++++++++++++++++
// import { leftPad } from "./numberUtils";

// export const convertDate = (date, type) => {
//   if (!date) return "- - - -";

//   const d = new Date(date);
//   if (isNaN(d.getTime())) return "Invalid Date";

//   const day = leftPad(d.getDate().toString(), 2, "0");
//   const month = leftPad((d.getMonth() + 1).toString(), 2, "0");
//   const year = d.getFullYear().toString();
//   const hours = leftPad(d.getHours().toString(), 2, "0");
//   const minutes = leftPad(d.getMinutes().toString(), 2, "0");

//   if (type === "number") {
//     return `${year}${month}${day}`;
//   }

//   return type
//     ? type === "time"
//       ? `${hours}:${minutes}`
//       : `${day}-${month}-${year}`
//     : `${day}-${month}-${year} | ${hours}:${minutes}`;
// };

import { leftPad } from "./numberUtils";

const parseDateString = (dateString) => {
  // Check if the dateString is in ISO format
  if (dateString.includes("T")) {
    const date = new Date(dateString);
    const localDateString = date.toLocaleString("en-US", { timeZone: "UTC" });
    return new Date(localDateString);
  }

  // Check if the dateString is in DD/MM/YYYY format
  const parts = dateString.split("/");
  if (parts.length === 3) {
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JavaScript Date
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  }

  // If the dateString format is not recognized, return null
  return null;
};

export const convertDate = (date, type) => {
  if (!date) return "- - - -";

  const d = parseDateString(date);
  if (!d || isNaN(d.getTime())) return "Invalid Date";

  const day = leftPad(d.getDate().toString(), 2, "0");
  const month = leftPad((d.getMonth() + 1).toString(), 2, "0");
  const year = d.getFullYear().toString();
  const hours = leftPad(d.getHours().toString(), 2, "0");
  const minutes = leftPad(d.getMinutes().toString(), 2, "0");
  if (type === "number") {
    return `${year}-${month}-${day}`;
  }

  return type
    ? type === "time"
      ? `${hours}:${minutes}`
      : `${day}-${month}-${year}`
    : `${day}-${month}-${year} | ${hours}:${minutes}`;
};
