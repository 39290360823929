// ** Redux Imports
import { THEMES } from "constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  layout: {
    theme: THEMES.LIGHT,
    direction: "ltr",
    responsiveFontSizes: true,
  },
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.layout.theme =
        action.payload === "dark" ? THEMES.DARK : THEMES.LIGHT;
    },
    resetLayout: () => {
      return { ...initialState };
    },
  },
});

export const layoutSelector = (state) => state.layout;
export const { setTheme, resetLayout } = layoutSlice.actions;

export default layoutSlice.reducer;
