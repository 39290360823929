// ** Third Party Components
import * as Yup from "yup";
import { useFormik } from "formik";

// ** Actions
import {
  useAddClientMutation,
  useUpdateClientMutation,
} from "store/clients/services";
import toast from "react-hot-toast";

const useFormClient = (id, data, handleCallback) => {
  const [addClient] = useAddClientMutation();
  const [updateClient] = useUpdateClientMutation();

  const initialValues = {
    note: data?.note || "",
    phone: data?.phone || "",
    email: data?.email,
    fullname: data?.fullname || "",
    professional: data?.professional || false,
  };

  const fieldValidationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Too Short")
      .required("Full Name is Required!"),
    email: Yup.string().email("Email is Not Valid!"),
    phone: Yup.string().min(10, "Too short").required("Phone is Required!"),
  });

  return useFormik({
    initialValues: initialValues,
    validationSchema: fieldValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        if (id) {
          const res = await updateClient({ id: id, ...values }).unwrap();
          await handleCallback(res);
          toast.success("Modification réussie !");
        } else {
          const res = await addClient({ ...values }).unwrap();
          await handleCallback(res);
          toast.success("Création réussie !");
        }
        helpers.resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.data?.message ?? "Quelque chose ne va pas ?");
        throw new Error("error");
      }
    },
  });
};
export default useFormClient;
