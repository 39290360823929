export const convertObjectToSearchParams = (obj) => {
  // remove undefined values without mutating the original object
  const searchParamsObj = Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

  const searchParams = new URLSearchParams(searchParamsObj).toString();
  return searchParams;
};

// export const convertObjectToSearchParams = (obj) => {
//   const searchParamsObj = Object.keys(obj).reduce((acc, key) => {
//     const value = obj[key];
//     if (value !== undefined && value !== null && value !== "") {
//       if (value instanceof Date) {
//         // Format date in a specific way, e.g., ISO string
//         acc[key] = value.toISOString();
//       } else if (Array.isArray(value)) {
//         // Join array elements into a comma-separated string or handle differently
//         acc[key] = value.join(',');
//       } else {
//         acc[key] = value;
//       }
//     }
//     return acc;
//   }, {});

//   return new URLSearchParams(searchParamsObj).toString();
// };
