import { Button, CircularProgress } from "@mui/material";

const LoadingButton = (props) => {
  const { loading, children, ...rest } = props;
  return (
    <Button {...rest}>
      {loading && (
        <CircularProgress
          sx={{
            color: "common.white",
            width: "20px !important",
            height: "20px !important",
            mr: (theme) => theme.spacing(2),
          }}
        />
      )}
      {children}
    </Button>
  );
};

export default LoadingButton;
