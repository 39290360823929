import { configureStore } from "@reduxjs/toolkit";
// config
import { apiSlice } from "./api";
import layoutSlice from "./layout";
import authSlice from "./auth/slices";
import clientSlice from "./clients/slices";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    client: clientSlice,
    layout: layoutSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
