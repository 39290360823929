// ** Router Import

import { Suspense } from "react";

// ** Router Import
import { useSelector } from "react-redux";

// ** Mui Import
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// ** Localization
import "dayjs/locale/fr";
import { frFR } from "@mui/x-date-pickers/locales";

// ** Router Import
import Router from "routes";

// ** Theme Imports
import { createCustomTheme } from "theme";

// ** Auth Provider
import AuthProvider from "providers/AuthProvider";
// ** Store
import { layoutSelector } from "store/layout";

// ** Toast Conainer
import { Toaster } from "react-hot-toast";

function App() {
  const { layout } = useSelector(layoutSelector);

  const theme = createCustomTheme({
    theme: layout.theme,
    direction: layout.direction,
    responsiveFontSizes: layout.direction,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale="fr"
          localeText={
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Suspense fallback={<div> loading...</div>}>
            <AuthProvider>
              <Router />
              <Toaster position="top-right" />
            </AuthProvider>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
