import { apiSlice } from "store/api";
import { convertObjectToSearchParams } from "utils/apiHelpers";

export const bookingsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      bookings: builder.query({
        query: () => `booking`,
        providesTags: ["bookings"],
      }),
      addBooking: builder.mutation({
        query: (data) => ({
          url: "/booking",
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["tables", "bookings", "siteweb", "attendance"],
      }),
      updateBooking: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/booking/${id}`,
          method: "PATCH",
          body: { ...data },
        }),
        invalidatesTags: ["tables", "bookings", "siteweb"],
      }),
      addBookingByUuid: builder.mutation({
        query: ({ uuid, ...data }) => ({
          url: `/booking/${uuid}`,
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["tables", "bookings", "siteweb"],
      }),
      bookedTables: builder.query({
        query: (searchParams) =>
          `booking/getBookedTables?${convertObjectToSearchParams(
            searchParams
          )}`,
        providesTags: ["tables", "bookings", "siteweb"],
      }),
      getTags: builder.query({
        query: () => `booking/getTags`,
        providesTags: ["tags"],
      }),
      createTag: builder.mutation({
        query: (data) => ({
          url: "/booking/createTag",
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["tags"],
      }),
      deleteTag: builder.mutation({
        query: (id) => ({
          url: `/booking/tag/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["tags"],
      }),
      getAttendance: builder.query({
        query: (searchParams) =>
          `booking/attendance?${convertObjectToSearchParams(searchParams)}`,
        providesTags: ["attendance"],
      }),
    };
  },
});

export const {
  useBookingsQuery,
  useAddBookingMutation,
  useUpdateBookingMutation,
  useAddBookingByUuidMutation,
  useBookedTablesQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetAttendanceQuery,
} = bookingsApi;
