import { Fragment } from "react";
import {
  Box,
  styled,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ProfilePopover from "./popovers/ProfilePopover";
import { useDispatch, useSelector } from "react-redux";
import { layoutSelector, setTheme } from "store/layout";
import { DarkMode, LightMode } from "@mui/icons-material";
import NotificationsPopover from "./popovers/Notifications";

const DashboardHeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const ToggleIcon = styled(Box)(({ theme, width }) => ({
  height: 3,
  margin: "5px",
  marginLeft: 0,
  width: width || 25,
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

const DashboardHeader = (props) => {
  const dispatch = useDispatch();
  const { setShowMobileSideBar } = props;
  const { layout } = useSelector(layoutSelector);

  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down(1200));

  const handleChangeTheme = (value) => {
    dispatch(setTheme(value));
  };

  return (
    <DashboardHeaderRoot position="sticky">
      <StyledToolBar>
        {downMd && (
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={setShowMobileSideBar}
          >
            <ToggleIcon />
            <ToggleIcon width={18} />
            <ToggleIcon width={9} />
          </Box>
        )}

        <Box flexGrow={1} ml={1} />

        {layout.theme === "light" ? (
          <StyledIconButton onClick={() => handleChangeTheme("dark")}>
            <DarkMode />
          </StyledIconButton>
        ) : (
          <StyledIconButton onClick={() => handleChangeTheme("light")}>
            <LightMode />
          </StyledIconButton>
        )}

        {upSm && (
          <Fragment>
            {/* <LanguagePopover /> */}
            <NotificationsPopover />
          </Fragment>
        )}

        <ProfilePopover />
      </StyledToolBar>
    </DashboardHeaderRoot>
  );
};

export default DashboardHeader;
