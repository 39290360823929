import { apiSlice } from "store/api";
// import { convertObjectToSearchParams } from "utils/apiHelpers";

export const notificationsApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      notification: builder.query({
        query: () => `notification`,
        providesTags: ["notifications"],
      }),
    };
  },
});

export const { useNotificationQuery } = notificationsApi;
