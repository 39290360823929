// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
// Init Auth State
const initialState = {
  user: {},
  isAuth: false,
};

// Auth Slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = true;
      state.user = action.payload.data ?? {};
    },
    setUserData: (state, action) => {
      console.log(action.payload);
      state.isAuth = true;
      state.user = action.payload ?? {};
    },
    resetAuth: () => {
      return { ...initialState };
    },
  },
});

export const getUserData = (state) => state.auth.user;
export const checkIsAuth = (state) => state.auth.isAuth;

export const authSelector = (state) => state.auth;
export const { setAuth, setUserData, resetAuth } = authSlice.actions;

export default authSlice.reducer;
