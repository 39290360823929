import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import useFormClient from "hooks/useFormClient";
import {
  H6,
  FlexBox,
  AppTextField,
  LoadingButton,
  FlexBetween,
} from "components";
import {
  useBookingsQuery,
  useGetAttendanceQuery,
} from "store/bookings/services";
import dayjs from "dayjs";
import { getUserData } from "store/auth/slices";
import { useSelector } from "react-redux";
import { useAllBookingsQuery } from "store/partnership/partnership";
import { useGetClienByPhoneQuery } from "store/clients/services";
import { useEffect, useState } from "react";
import { convertDate } from "utils/dateUtils";
import { subMonths, isAfter, startOfToday } from "date-fns";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { isValidNumber } from 'libphonenumber-js';

const ClientFormReservation = ({ edit, data, onClose, handleCallback }) => {
  const [skip, setSkip] = useState(true);
  const [clientId, setClientId] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false); // Track phone validity status


  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    isValid,
    isSubmitting,
    setFieldValue,
    validateForm,
  } = useFormClient(clientId || data?.id, data, handleCallback);

  const { data: client, isLoading } = useGetClienByPhoneQuery(values.phone, {
    skip,
  });

  useEffect(() => {
    setSkip(true);
    if (!edit) {
      if (client) {
        setClientId(client.id);
        setFieldValue("email", client.email || "");
        setFieldValue("fullname", client.fullname);
        setFieldValue("professional", client.professional);
        validateForm({
          fullname: client.fullname,
          phone: values.phone,
        });
      } else {
        setClientId();
        setFieldValue("email", "");
        setFieldValue("fullname", "");
      }
    }
    console.log("client eheh", client);
    // eslint-disable-next-line
  }, [client]);

  // New code starts here
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [dateValue, setDateValue] = useState(dayjs().format("YYYY-MM-DD"));
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [ids, setIds] = useState([]);
  const [partnerIds, setPartnerIds] = useState([]);

  const { data: bookingsData } = useBookingsQuery();
  const { data: partnersReservation } = useAllBookingsQuery();
  const { data: attendance, refetch } = useGetAttendanceQuery({
    date: dateRange.startDate,
  });

  const { startDate, endDate } = dateRange;
  const startOfDay = dayjs(startDate).startOf("day").toDate();
  const endOfDay = dayjs(endDate).endOf("day");

  const [sum_Pax, setSum_Pax] = useState(0);
  const user = useSelector(getUserData);

  useEffect(() => {
    if (!bookingsData || !partnersReservation) return;

    const parseDate = (dateString) => new Date(dateString);

    if (user.role === "admin" || user.role === "marketer") {
      const startOfDayDate = dayjs(startDate).startOf("day").toDate();
      const endOfDayDate = dayjs(endDate).endOf("day").toDate();

      const filteredData = bookingsData.filter((item) => {
        const itemDate = parseDate(item.checkin);
        return (
          itemDate >= startOfDayDate &&
          itemDate <= endOfDayDate &&
          !["canceled", "noshow", "gone"].includes(item.status)
        );
      });

      const filteredPartnersReservation = partnersReservation.filter((item) => {
        const itemDate = parseDate(item.checkin);
        return (
          itemDate >= startOfDayDate &&
          itemDate <= endOfDayDate &&
          !["canceled", "noshow", "gone"].includes(item.status)
        );
      });

      const combinedTables = [...filteredData, ...filteredPartnersReservation];
      setIds(combinedTables);
      setPartnerIds(filteredPartnersReservation);
    } else {
      const filteredData = bookingsData.filter((item) => {
        const itemDate = parseDate(item.checkin);
        const selectedDate = dayjs(dateValue).set("hour", 20).toDate();
        return (
          itemDate >= selectedDate &&
          itemDate < dayjs(selectedDate).add(7, "hours").toDate() &&
          !["canceled", "noshow"].includes(item.status)
        );
      });

      const filteredPartnersReservation = partnersReservation.filter((item) => {
        const itemDate = parseDate(item.checkin);
        const selectedDate = dayjs(dateValue).set("hour", 20).toDate();
        return (
          itemDate >= selectedDate &&
          itemDate < dayjs(selectedDate).add(7, "hours").toDate() &&
          !["canceled", "noshow"].includes(item.status)
        );
      });

      const combinedTables = [...filteredData, ...filteredPartnersReservation];
      setIds(combinedTables);
      setPartnerIds(filteredPartnersReservation);
    }
  }, [
    bookingsData,
    partnersReservation,
    startDate,
    endDate,
    dateValue,
    user.role,
  ]);

  // Find the latest 3 reservations for the client
  const clientBookings =
    bookingsData?.filter((booking) => booking.clientId === client?.id) || [];

  const threeMonthsAgo = subMonths(startOfToday(), 3);

  // Filter for bookings that are after threeMonthsAgo
  const filteredBookings = clientBookings.filter((booking) =>
    isAfter(new Date(booking.checkin), threeMonthsAgo)
  );

  const sortedBookings = filteredBookings
    .sort((a, b) => new Date(b.checkin) - new Date(a.checkin)) // Sort by checkin date descending
    .slice(0, 3); // Get the latest 3 reservations
    const validatePhone = (phone) => {
      // Moroccan phone numbers should start with +212 and have 9 digits following the country code
      const valid = isValidNumber(phone, "MA"); // 'MA' for Morocco
    setIsPhoneValid(valid); // Set phone validity status
    return valid;
    };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} className="main-form">
        <Grid item xs={12}>
          <H6 mb={1}>Téléphone</H6>
          <FlexBetween gap={2}>
        <ReactPhoneInput
          country={"ma"} // Set default country to Morocco
          value={values.phone}
          onChange={(value) => handleChange({ target: { name: "phone", value } })}
          inputStyle={{ width: "100%" }} // Style the input
          disabled={edit && data.client?.phone}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          isValid={() => {
            const isValidPhone = validatePhone(values.phone);
            if (!isValidPhone) {
              return "Numéro de téléphone invalide"; // Show error message
            }
            return true;
          }}
        />
        <Button variant="outlined" onClick={() => setSkip(false)}>
          Check
        </Button>
      </FlexBetween>
        </Grid>

        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Nom Complet</H6>
          <AppTextField
            fullWidth
            size="small"
            name="fullname"
            placeholder="John"
            onChange={handleChange}
            value={values.fullname}
            disabled={(edit && data.client?.fullname) || isLoading}
            error={Boolean(errors.fullname && touched.fullname)}
            helperText={touched.fullname && errors.fullname}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <H6 mb={1}>Email</H6>
          <AppTextField
            fullWidth
            size="small"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="uilib@gmail.com"
            disabled={(edit && data.client?.email) || isLoading}
            error={Boolean(errors.email && touched.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>
        {client && (
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", marginTop: 3 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                borderRadius: 2,
                bgcolor: "#757C92", // Custom grey color
                fontWeight: "bold",
                color: "white",
                marginBottom: 2,
              }}
            >
              <Box>Réservations Récentes</Box>
              <Box
                sx={{
                  padding: "4px 8px",
                  borderRadius: 1,
                  bgcolor: client.isElite ? "gold" : "gray", // Gold for elite, gray otherwise
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {client.isElite ? "Elite" : "Non-Elite"}
              </Box>
            </Box>

            <TableContainer component={Paper} sx={{ paddingLeft: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pax</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedBookings.length > 0 ? (
                    sortedBookings.map((booking) => (
                      <TableRow key={booking.id}>
                        <TableCell>{client.fullname || "N/A"}</TableCell>
                        <TableCell>
                          {booking.tags?.map((tag) => tag.name).join(", ") ||
                            "N/A"}
                        </TableCell>
                        <TableCell>{convertDate(booking.checkin)}</TableCell>
                        <TableCell>
                          {(booking.adults || 0) + (booking.children || 0)}
                        </TableCell>
                        <TableCell>{booking.status}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography variant="body2">
                          Aucune réservation
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>

      <FlexBox justifyContent="flex-end" gap={2} marginTop={3}>
        <LoadingButton
          fullWidth
          variant="outlined"
          onClick={onClose}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Annuler
        </LoadingButton>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting || !isPhoneValid}
        >
          Enregistrer
        </LoadingButton>
      </FlexBox>
    </form>
  );
};

export default ClientFormReservation;
