import { Box, Button, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FlexBetween, FlexBox, SearchInput } from "components";

dayjs.extend(utc);

// styled component
const StyledFlexBox = styled(FlexBetween)(({ theme }) => ({
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "100%",
    },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const ListHeader1 = ({
  day,
  handleClick,
  setDateValue,
  setSearchValue,
  buttonText = "Add New",
}) => {
  return (
    <StyledFlexBox>
      <FlexBox alignItems="center">
        {setSearchValue && (
          <Box pr={2}>
            <SearchInput
              disable_border
              placeholder="Search..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Box>
        )}

        {setDateValue && (
          <Box pr={2}>
            <DatePicker
              label={"Date"}
              views={day ? ["year", "month", "day"] : ["month", "year"]}
              // value={dayjs(values.startDate)}
              onChange={(newValue) => {
                console.log(
                  "datechosen " + dayjs(newValue).format("YYYY-MM-DD")
                );
                setDateValue(
                  dayjs(newValue).format("YYYY-MM-DD")
                  // dayjs(newValue).format("YYYY-MM-DD")
                );
              }}
              slotProps={{
                textField: {
                  size: "small",
                  // fullWidth: true,
                },
              }}
            />
          </Box>
        )}
      </FlexBox>

      {handleClick && (
        <Button variant="contained" onClick={handleClick}>
          {buttonText}
        </Button>
      )}
    </StyledFlexBox>
  );
};

export default ListHeader1;
