import { Box, Button, styled } from "@mui/material";

import LayoutDrawer from "./LayoutDrawer";
import MultiLevelMenu from "./MultiLevelMenu";
import { FlexBox, H1, Scrollbar } from "components";
import { LoginOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { clientLogout } from "store/api";
import { resetAuth } from "store/auth/slices";

const TOP_HEADER_AREA = 70;
const BOTTOM_FOOTER_AREA = 80;

const NavWrapper = styled(Box)(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));

const FooterWrapper = styled(Box)(() => ({
  paddingTop: 16,
  paddingLeft: 16,
  paddingRight: 16,
}));

const MobileSidebar = (props) => {
  const { sidebarCompact, showMobileSideBar, setShowMobileSideBar } = props;
  const dispatch = useDispatch();

  const handleLogOut = () => {
    clientLogout();
    dispatch(resetAuth());
  };
  return (
    <LayoutDrawer open={showMobileSideBar} onClose={setShowMobileSideBar}>
      <Box p={2} height={TOP_HEADER_AREA}>
        <FlexBox ml={1.5}>
          {/* <img src="/static/logo/logo.png" alt="logo" width={200} /> */}
          <H1>Resa</H1>
        </FlexBox>
      </Box>

      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: "hidden",
          height: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
        }}
      >
        <NavWrapper compact={sidebarCompact}>
          <MultiLevelMenu sidebarCompact={false} />
        </NavWrapper>
      </Scrollbar>
      <FooterWrapper height={BOTTOM_FOOTER_AREA}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleLogOut}
          startIcon={<LoginOutlined />}
        >
          Log out
        </Button>
      </FooterWrapper>
    </LayoutDrawer>
  );
};

export default MobileSidebar;
