import React from "react";
import {
  Select,
  styled,
  MenuItem,
  InputBase,
  Button,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

export const StyledSelectInput = styled(InputBase)(({ theme }) => ({
  height: 40,
  fontSize: 12,
  width: "100%",
  fontWeight: 600,
  padding: "0 8px",
  border: "1px solid",
  borderRadius: "8px",
  color: theme.palette.text.primary,
  borderColor: theme.palette.divider,
  "& .MuiPopover-paper": {
    boxShadow: "none",
  },
  "& > .MuiSelect-select": {
    paddingRight: "0 !important",
  },
}));

const AppSelect = (props) => {
  const { options, multiple, delete: deleteProp, onDelete, ...rest } = props;
  // console.log("Options:", options);
  // console.log("Rest:", rest);
  // console.log("Props:", props);
  const renderSelectedValue = (selected) => {
    // For multiple selections, selected will be an array
    if (multiple) {
      const selectedLabels = options
        .filter((option) => selected.includes(option.value))
        .map((option) => option.label);
      return selectedLabels.join(", ");
    }
    // For single selection, selected will be a single value
    const selectedOption = options.find((option) => option.value === selected);
    return selectedOption ? selectedOption.label : "";
  };
  return (
    <Select
      {...rest}
      multiple={multiple}
      renderValue={renderSelectedValue}
      // input={<StyledSelectInput />}
    >
      {options?.map((e) => (
        <MenuItem
          key={e.value}
          value={e.value}
          disabled={false}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {e.label}
          {deleteProp && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onDelete(e.value);
              }}
            >
              <Delete />
            </IconButton>
          )}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AppSelect;
