// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    resetClient: () => {
      return { ...initialState };
    },
  },
});

export const clientSelector = (state) => state.client;
export const { setClient, resetClient } = clientSlice.actions;

export default clientSlice.reducer;
