// ** React Imports
import { lazy } from "react";

// ** Router imports
import { Navigate, useRoutes } from "react-router-dom";

// ** Layouts
import Layout from "layouts/DashboardLayout";

// ** Route Components
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";

import { useSelector } from "react-redux";
import { getUserData } from "store/auth/slices";
import PartnerPayment from "pages/dashboard/partnerPayment/payment";
import ReservationNormal from "pages/dashboard/ReservationNormal/ReservationNormal";
import Statistics from "pages/dashboard/Statistique/Statistics";
import ReservationNormalCreation from "pages/dashboard/ReservationNormalCreation/ReservationNormal";
// ** Routes
const Login = lazy(() => import("../pages/auth/Login"));

// const Home = lazy(() => import("../pages/dashboard/Home"));
const Statics = lazy(() => import("../pages/dashboard/statics"));
const Users = lazy(() => import("../pages/dashboard/user/Users"));
const Clients = lazy(() => import("../pages/dashboard/client/Clients"));
const Orders = lazy(() => import("../pages/dashboard/order/Orders"));
const Bookings = lazy(() => import("../pages/dashboard/booking/Bookings"));
const Campaigns = lazy(() => import("../pages/dashboard/Campaign/Campaigns"));
const Getintouch = lazy(() => import("../pages/dashboard/booking/Getintouch"));
const Salespeople = lazy(() =>
  import("../pages/dashboard/Salespeople/Salespeople")
);
const Partners = lazy(() => import("../pages/dashboard/partners/Partners"));
const Siteweb = lazy(() => import("../pages/dashboard/siteweb/Siteweb"));
const Partnership = lazy(() =>
  import("../pages/dashboard/partnership/Partnership")
);
const Infos = lazy(() => import("../pages/dashboard/infos/Infos"));

export const authRoutes = [
  {
    path: "login",
    element: <Login />,
    meta: {
      restricted: true,
      publicRoute: true,
    },
  },
];

export const blankRoutes = [
  {
    path: "getintouch/:uuid",
    element: <Getintouch />,
    meta: {
      publicRoute: true,
    },
  },
];

export const dashRoutes = [
  {
    path: "statics",
    element: <Statics />,
    meta: {
      roles: ["admin"],
    },
  },
  {
    path: "clients",
    element: <Clients />,
    meta: {
      roles: ["admin"],
    },
  },
  {
    path: "partners",
    element: <Partners />,
    meta: {
      roles: ["admin", "headsales"],
    },
  },
  {
    path: "siteweb",
    element: <Siteweb />,
    meta: {
      roles: ["admin", "restaurateur", "bureau"],
    },
  },
  {
    path: "campaigns",
    element: <Campaigns />,
    meta: {
      roles: ["admin"],
    },
  },
  {
    path: "salespeople",
    element: <Salespeople />,
    meta: {
      roles: ["admin", "headsales"],
    },
  },
  {
    path: "partnership",
    element: <Partnership />,
    meta: {
      roles: ["admin", "headsales"],
    },
  },
  {
    path: "bookings",
    element: <Bookings />,
    meta: {
      roles: ["admin", "headsales", "restaurateur", "bureau"],
    },
  },
  {
    path: "users",
    element: <Users />,
    meta: {
      roles: ["admin", "bureau"],
    },
  },
  {
    path: "orders",
    element: <Orders />,
    meta: {
      roles: ["admin", "cashier"],
    },
  },
  {
    path: "infos",
    element: <Infos />,
    meta: {
      roles: ["admin"],
    },
  },
  {
    path: "partners-payments",
    element: <PartnerPayment />,
    meta: {
      roles: ["admin", "cashier"],
    },
  },
  {
    path: "resa-dep-com",
    element: <ReservationNormal />,
    meta: {
      roles: ["admin", "marketer"],
    },
  },
  {
    path: "statistics",
    element: <Statistics />,
    meta: {
      roles: ["admin", "marketer"],
    },
  },
  {
    path: "resa-Creation",
    element: <ReservationNormalCreation />,
    meta: {
      roles: ["admin", "marketer"],
    },
  },
  
  
];

// ** Return Filtered Array of Routes & Paths
const MergeRoutes = (routes) => {
  const LayoutRoutes = [];

  if (routes) {
    routes.filter((route) => {
      let RouteTag = PrivateRoute;
      if (route.meta) {
        RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
      }
      route.element = <RouteTag route={route}>{route.element}</RouteTag>;
      LayoutRoutes.push(route);
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const Router = () => {
  const DashRoutes = MergeRoutes(dashRoutes);
  const BlankRoutes = MergeRoutes(blankRoutes);

  const user = useSelector(getUserData);

  const userRole = user?.role;
  const defaultPath = getDefaultPath(userRole);

  function getDefaultPath(role) {
    switch (role) {
      case "admin":
      case "marketer":
      case "headsales":
      case "restaurateur":
      case "bureau":
        return "/bookings";
      case "cashier":
        return "/orders";
      default:
        return "/login"; // default path for unrecognized roles
    }
  }
  // ...
  const routes = useRoutes([
    {
      path: "/",
      element: <Navigate replace to={defaultPath} />,
    },
    ...authRoutes,
    ...BlankRoutes,
    {
      path: "/",
      element: <Layout />,
      children: [...DashRoutes],
    },
  ]);

  return routes;
};

export default Router;
