import { /*useEffect, useMemo,*/ useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import timezone from "dayjs/plugin/timezone";
import {
  Box,
  Button,
  IconButton,
  Typography,
  styled,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  CustomTable,
  FlexBox,
  H6,
  ListHeader,
  Paragraph,
  Small,
  Span,
  Tiny,
} from "components";
import ListHeader1 from "components/ListHeaders/ListHeader1";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { convertDate } from "utils/dateUtils";
import {
  useBookingsQuery,
  useUpdateBookingMutation,
  useGetAttendanceQuery,
} from "store/bookings/services";
// import AddBooking from "../booking/AddBooking";
import { useAllBookingsQuery } from "store/partnership/partnership";
import Page from "../booking/pagetable";
import { getUserData } from "store/auth/slices";
import { useSelector } from "react-redux";
import {
  useGetTagsQuery,
  useDeleteTagMutation,
  useCreateTagMutation,
} from "store/bookings/services"; // Import the relevant

import { CanalDropdown, TagsDropdown, StatusDropdown } from "./dropDownFilters"; // Adjust the import path according to your project structure
import { useMemo } from "react";

dayjs.extend(utc);
const columnShape = [
  {
    Header: "Provenance",
    accessor: "user",
    Cell: ({ row }) => {
      const { user, client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {user && user.firstname ? (
              <Tiny color="text.disabled">{user.firstname}</Tiny>
            ) : (
              <Tiny color="text.disabled">WEB</Tiny>
            )}
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Client",
    accessor: "id",
    Cell: ({ row }) => {
      const { client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{client?.fullname || "fullname"}</H6>
            <Paragraph color="text.secondary">{client?.phone}</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Tags",
    accessor: "tags",
    Cell: ({ row }) => {
      const { tags } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {tags.map((tag) => (
              <H6 key={tag.id} color="text.primary">
                {tag.name}
              </H6>
            ))}
          </FlexBox>
        </FlexBox>
      );
    },
  },

  // {
  //   Header: "Date de création",
  //   accessor: "createdAt",
  //   Cell: ({ row }) => {
  //     const { createdAt } = row.original;
  //     return <Span>{convertDate(createdAt, "number")}</Span>;
  //   },
  // },

  {
    Header: "Note",
    accessor: "note",
  },

  {
    Header: () => {
      return (
        <p>
          Arrivée
          <br />
          <Tiny color="text.secondary">(Création)</Tiny>
        </p>
      );
    },
    accessor: "checkin",
    Cell: ({ row }) => {
      const { checkin, createdAt } = row.original;
      return (
        <Span>
          {convertDate(checkin)}
          <br />
          <Tiny color="text.secondary">
            ({convertDate(createdAt, "number")})
          </Tiny>
        </Span>
      );
    },
  },

  {
    Header: "Statut",
    minWidth: " 110px",
    accessor: "status",
    Cell: ({ row }) => {
      const { status } = row.original;
      const getStatus = (val) => {
        switch (val) {
          case "canceled":
            return "Annulé";
          case "confirmed":
            return "Confirmé";
          case "installed": //to be checked
            return "Installé";
          case "alert":
            return "Alerte";
          case "gone":
            return "Reparti";
          case "noshow":
            return "No Show";
          default:
            return "En attente";
        }
      };
      return (
        <Small
          sx={{
            width: 100,
            fontSize: 14,
            color: "black",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor:
              status === "canceled"
                ? "#9e9e9e"
                : status === "confirmed"
                ? "#4caf50"
                : status === "installed"
                ? "#2196f3"
                : status === "gone"
                ? "#ffffff"
                : status === "alert"
                ? "#f44336"
                : status === "noshow"
                ? "#F5EEC8"
                : "#ff9933",
          }}
        >
          {getStatus(status)}
        </Small>
      );
    },
  },
  {
    Header: "Table",
    accessor: "table",
    Cell: ({ row }) => {
      const { tables } = row.original;
      return tables.map((table) => (
        <Span key={table.id}>
          {table.tableRef}
          <br />
        </Span>
      ));
    },
  },

  // {
  //   Header: "Adultes",
  //   accessor: "adults",
  // },

  // {
  //   Header: "Enfants",
  //   accessor: "children",
  // },

  {
    Header: "Personnes",
    accessor: "persons",
    Cell: ({ row }) => {
      const { adults, children } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{adults} Adultes</H6>
            <Paragraph color="text.secondary">{children} Enfants</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Canal",
    accessor: "canal",
    Cell: ({ row }) => {
      const { canal } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
          <H6 color="text.primary">{canal || "googleAds"}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Addition",
    accessor: "amount",
    Cell: ({ row }) => {
      const { amount } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{amount}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },

  // {
  //   Header: "Check-out",
  //   accessor: "checkout",
  //   Cell: ({ row }) => {
  //     const { setFieldValue, handleSubmit } = useFormBooking(
  //       true,
  //       row.original,
  //       () => {}
  //     );
  //     useEffect(() => {
  //       setFieldValue("checkout", dayjs().utc(true));
  //       setFieldValue("status", "gone");
  //     }, [setFieldValue]);
  //     return (
  //       <>
  //         <Button
  //           sx={{
  //             color: "white",
  //             cursor: "pointer",
  //             padding: 1,
  //             borderRadius: 1,
  //             bgcolor: "green",
  //             "&:hover": {
  //               bgcolor: "darkgreen",
  //             },
  //           }}
  //           onClick={() => {
  //             handleSubmit();
  //           }}
  //         >
  //           <Span>Check out</Span>
  //         </Button>
  //       </>
  //     );
  //   },
  // },
];

const StyledRectangle2 = styled(Box)(({ theme }) => ({
  backgroundColor: "#1A2130",
  color: "white",
  padding: theme.spacing(1, 2), // Adjust padding for better responsiveness
  borderRadius: 5,
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", // Ensures the component takes full width of its container
  boxSizing: "border-box", // Ensures padding is included in the width calculation

  [theme.breakpoints.down("sm")]: {
    // Adjust styles for small screens
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },

  [theme.breakpoints.up("md")]: {
    // Adjust styles for medium and larger screens
    padding: theme.spacing(1, 3), // Adjust padding as needed
  },
}));

const Statistics = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [dateValue, setDateValue] = useState(dayjs().format("YYYY-MM-DD"));
  const [searchValue, setSearchValue] = useState("");
  const [selectedCanal, setSelectedCanal] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const { data } = useBookingsQuery();
  const { data: partnersReservation } = useAllBookingsQuery();
  const { refetch } = useGetAttendanceQuery({ date: dateRange.startDate });
  const user = useSelector(getUserData);
  const { startDate, endDate } = dateRange;
  const startOfDay = dayjs(startDate).startOf("day").toDate();
  const endOfDay = dayjs(endDate).endOf("day");

  useEffect(() => {
    refetch();
  }, [dateRange, refetch]);

  useEffect(() => {
    if (!data || !partnersReservation) return;

    const parseDate = (dateString) => new Date(dateString);

    const combinedData = [...data, ...partnersReservation].filter((item) => {
      const itemDate = parseDate(item.checkin);
      return (
        itemDate >= startOfDay &&
        itemDate <= endOfDay &&
        (selectedCanal ? item.canal === selectedCanal : true) &&
        (selectedStatus ? item.status === selectedStatus : true) &&
        (selectedTags.length
          ? item.tags.some((tag) => selectedTags.includes(tag.id))
          : true) &&
        item.client?.fullname.toLowerCase().includes(searchValue.toLowerCase())
      );
    });

    setFilteredData(combinedData);
  }, [
    data,
    partnersReservation,
    startDate,
    endDate,
    searchValue,
    selectedCanal,
    selectedTags,
    selectedStatus,
  ]);

  // Filter data for totals based on role
  const filteredForTotals = useMemo(() => {
    return user.role === "marketer"
      ? filteredData.filter(
          (item) => item.status === "installed" || item.status === "gone"
        )
      : filteredData;
  }, [filteredData, user.role]);

  const totalClients = filteredData.length;
  const totalAdults = filteredForTotals.reduce(
    (sum, item) => sum + (item.adults || 0),
    0
  );
  const totalChildren = filteredForTotals.reduce(
    (sum, item) => sum + (item.children || 0),
    0
  );
  const totalPAX = totalAdults + totalChildren;
  const totalActive = filteredData.filter(
    (item) => item.status === "installed" || item.status === "confirmed"
  ).length;
  const totalAmount = filteredForTotals.reduce(
    (sum, item) => sum + (item.amount || 0),
    0
  ); // Assuming each item has an 'amount' field

  return (
    <Box pt={2} pb={4}>
      {user.role === "admin" || user.role === "marketer" ? (
        <ListHeader
          setDateValue={setDateRange}
          setSearchValue={setSearchValue}
        />
      ) : (
        <ListHeader1
          day
          setDateValue={setDateValue}
          buttonText="Ajouter une Réservation"
          setSearchValue={setSearchValue}
          handleClick={() => setOpenModal(true)}
        />
      )}

      <Box mb={2} display="flex" gap={2} alignItems={"center"} justifyContent={"center"}> 
        <CanalDropdown
          selectedCanal={selectedCanal}
          setSelectedCanal={setSelectedCanal}
        />
        <TagsDropdown
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <StatusDropdown
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </Box>

      {(user.role === "restaurateur" ||
        user.role === "admin" ||
        user.role === "receptionist" ||
        user.role === "headsales") && (
        <Box>
          <StyledRectangle2 mt={5}>
            <Typography variant="h6">Réservations</Typography>
          </StyledRectangle2>
        </Box>
      )}

      {filteredData && (
        <CustomTable
          data={{
            data: filteredData.sort(
              (a, b) => new Date(a.checkin) - new Date(b.checkin)
            ),
          }}
          hidePagination={true}
          columnShape={columnShape}
        />
      )}

      <Box
        p={2}
        bgcolor="#433D8B"
        borderRadius={2}
        mb={2}
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          "& > *": {
            color: "#ffffff",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{ flexGrow: 1, ml: 2 }}
        >
          <Typography>Réservations: {totalClients}</Typography>
          <Typography>Actives: {totalActive}</Typography>
          <Typography>Adultes: {totalAdults}</Typography>
          <Typography>Enfants: {totalChildren}</Typography>
          <Typography>PAX: {totalPAX}</Typography>
          <Typography>CA: {totalAmount}</Typography>{" "}
          {/* Displaying the CA total */}
        </Box>
      </Box>
    </Box>
  );
};

export default Statistics;
