import { apiSlice } from "store/api";

const paymentApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      payments: builder.query({
        query: () => "partner-payment/get-partners-payments",
        providesTags: ["payments"],
      }),
      payOffBookings: builder.mutation({
        query: (data) => ({
          url: "partner-payment/pay-off-bookings",
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["payments"],
      }),
    };
  },
});

export const { usePaymentsQuery, usePayOffBookingsMutation } = paymentApi;
