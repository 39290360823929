import * as React from "react";
import * as Mui from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import dayjs from "dayjs";
import { utc } from "dayjs";
import { CustomTable, SearchInput } from "components";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  usePaymentsQuery,
  usePayOffBookingsMutation,
} from "store/partnerPayment/payment";
import { convertDate } from "utils/dateUtils";
import toast from "react-hot-toast";

dayjs.extend(utc);

const PartnerPayment = () => {
  const [date, setDate] = React.useState(dayjs());
  const [searchValue, setSearchValue] = React.useState("");
  const [status, setStatus] = React.useState(2);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [payOffBookings] = usePayOffBookingsMutation();
  const { data, isLoading, isError } = usePaymentsQuery();

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((row) => row !== id));
    } else {
      setSelectedRows(selectedRows.concat(id));
    }
  };

  const columnShape = [
    {
      Header: () => {
        if (status === 1) return null;
        if (
          (data || [])
            .filter((row) => !row.paid)
            .filter((row) =>
              row.Partner.name.toLowerCase().includes(searchValue)
            )
            .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
            }).length === 0
        )
          return null;
        // if (status === 2 && selectedRows.length === 0) return null;
        return (
          <Mui.Checkbox
            checked={
              selectedRows.length > 0 &&
              selectedRows.length ===
                (data || [])
                  .filter((row) => !row.paid)
                  .filter((row) =>
                    row.Partner.name.toLowerCase().includes(searchValue)
                  )
                  .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
                  }).length
            }
            onChange={() => {
              setSelectedRows(
                selectedRows.length ===
                  (data || [])
                    .filter((row) => {
                      return !row.paid;
                    })
                    .filter((row) =>
                      row.Partner.name.toLowerCase().includes(searchValue)
                    )
                    .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
                    }).length
                  ? []
                  : data
                      .filter((row) => {
                        return !row.paid;
                      })
                      .filter((row) =>
                        row.Partner.name.toLowerCase().includes(searchValue)
                      )
                      .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
                      })
                      .map((row) => row.id)
              );
            }}
          />
        );
      },
      accessor: "id",
      Cell: ({ row }) => {
        const { id, paid } = row.original;
        return paid ? null : (
          <Mui.Checkbox
            checked={selectedRows.includes(id)}
            onChange={() => handleSelectRow(id)}
          />
        );
      },
    },
    {
      Header: "Nom / Prénom",
      accessor: "name",
      Cell: ({ row }) => {
        const { name } = row.original.Partner;
        return <Mui.Typography>{name}</Mui.Typography>;
      },
    },
    {
      Header: "Date et heure",
      accessor: "checkin",
      Cell: ({ row }) => {
        const { checkin } = row.original;
        return <Mui.Typography>{convertDate(checkin)}</Mui.Typography>;
      },
    },
    {
      Header: "Tables",
      accessor: "tables",
      Cell: ({ row }) => {
        const { tables } = row.original;
        return (
          <Mui.Typography>
            {tables.map((table) => table.id).join(", ")}
          </Mui.Typography>
        );
      },
    },
    {
      Header: "Addition",
      accessor: "amount",
      Cell: ({ row }) => {
        const { amount } = row.original;
        return <Mui.Typography>{amount} DHS</Mui.Typography>;
      },
    },
    {
      Header: "Comission",
      accessor: "comission",
      Cell: ({ row }) => {
        const { amount } = row.original;
        return <Mui.Typography>{amount / 10} DHS</Mui.Typography>;
      },
    },
    {
      Header: "Status",
      accessor: "paid",
      Cell: ({ row }) => {
        const { paid } = row.original;
        return (
          <Mui.Typography
            sx={{
              color: paid ? "green" : "red",
            }}
          >
            {paid ? "Payé" : "Non payé"}
          </Mui.Typography>
        );
      },
    },
  ];

  return (
    <Mui.Grid container spacing={2} pt={2} pb={4}>
      <Mui.Grid item xs={12} display="flex" alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <Mui.Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "35%",
            }}
          >
            <SearchInput
              disable_border
              placeholder="Rechercher..."
              onChange={(e) => {
                setSearchValue(e.target.value.toLowerCase());
              }}
            />
          </Mui.Box>
          <DatePicker
            label="Date"
            value={date}
            onChange={(value) => {
              setDate(value);
            }}
            format="YYYY-MM-DD"
          />
        </LocalizationProvider>
        <Mui.Button
          sx={{
            padding: 1,
            borderRadius: 1,
            bgcolor: "red",
            ml: 2,
            display: "flex",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
          disabled={status === 0}
          onClick={() => setStatus(0)}
        >
          Non payé
        </Mui.Button>
        <Mui.Button
          sx={{
            padding: 1,
            borderRadius: 1,
            bgcolor: "green",
            ml: 2,
            display: "flex",
            alignItems: "center",
            color: "white",
            fontWeight: "bold",
          }}
          disabled={status === 1}
          onClick={() => setStatus(1)}
        >
          Payé
        </Mui.Button>
        <Mui.Button
          sx={{
            padding: 1,
            borderRadius: 1,
            bgcolor: "white",
            ml: 2,
            display: "flex",
            alignItems: "center",
            color: "black",
            fontWeight: "bold",
          }}
          disabled={status === 2}
          onClick={() => setStatus(2)}
        >
          Tous
        </Mui.Button>
      </Mui.Grid>
      <Mui.Grid item xs={12}>
        {isLoading && <Mui.CircularProgress />}
        {isError && (
          <Mui.Alert severity="error">
            Erreur lors du chargement des données
          </Mui.Alert>
        )}
        {data && (
          <CustomTable
            data={{
              data: [...data]
                .filter((row) => {
                  if (status === 0) return !row.paid;
                  if (status === 1) return row.paid;
                  return true;
                })
                .filter((row) =>
                  row.Partner.name.toLowerCase().includes(searchValue)
                )
                .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
                }),
            }}
            columnShape={columnShape}
            hidePagination={true}
          />
        )}
      </Mui.Grid>
      {selectedRows.length > 0 && status !== 1 && (
        <Mui.Grid item xs={12}>
          <Mui.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              padding: 2,
              bgcolor: "white",
              borderRadius: 1,
            }}
          >
            <Mui.Box
              sx={{
                padding: 1,
                borderRadius: 1,
                bgcolor: "grey",
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MuiIcons.BookOnline />
              <Mui.Typography sx={{ ml: 1 }}>
                {selectedRows.length}{" "}
                {selectedRows.length > 1
                  ? "Réservations sélectionnées"
                  : "Réservation sélectionnée"}
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box
              sx={{
                padding: 1,
                borderRadius: 1,
                bgcolor: "grey",
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MuiIcons.Money />
              <Mui.Typography sx={{ ml: 1 }}>
                {selectedRows.reduce((acc, row) => {
                  return data.find((item) => item.id === row).amount + acc;
                }, 0)}{" "}
                DHS
              </Mui.Typography>
            </Mui.Box>
            <Mui.Box
              sx={{
                padding: 1,
                borderRadius: 1,
                bgcolor: "grey",
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
              }}
            >
              <MuiIcons.MonetizationOn />
              <Mui.Typography sx={{ ml: 1 }}>
                {selectedRows.reduce((acc, row) => {
                  return data.find((item) => item.id === row).amount / 10 + acc;
                }, 0)}{" "}
                DHS
              </Mui.Typography>
            </Mui.Box>
            <Mui.Button
              sx={{
                padding: 1,
                borderRadius: 1,
                bgcolor: "green",
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={async () => {
                console.log("selectedRows", selectedRows);
                console.log(
                  "selectedRows",
                  selectedRows.map((row) =>
                    data.find((item) => item.id === row)
                  )
                );
                console.log(
                  "data",
                  data
                    .filter((row) => {
                      return !row.paid;
                    })
                    .filter((row) =>
                      row.Partner.name.toLowerCase().includes(searchValue)
                    )
                    .filter((row) => {
                  const checkin = dayjs.utc(row.checkin).format("YYYY-MM-DD");
                  const formatedDate = date.format("YYYY-MM-DD");
                  return checkin === formatedDate;
                    })
                );
                await payOffBookings(selectedRows)
                  .then(() => {
                    toast.success("Paiement effectué avec succès");
                    setSelectedRows([]);
                  })
                  .catch(() => {
                    toast.error("Erreur lors du paiement");
                  });
              }}
            >
              Payer
            </Mui.Button>
          </Mui.Box>
        </Mui.Grid>
      )}
    </Mui.Grid>
  );
};

export default PartnerPayment;
