// ** React Imports
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getUserData } from "store/auth/slices";

// ** Utils

const PublicRoute = ({ children, route }) => {
  const user = useSelector(getUserData);

  if (route) {
    const restrictedRoute = route.meta && route.meta.restricted;

    if (user && restrictedRoute) {
      return <Navigate to={"/"} />;
    }
  }

  return <Suspense fallback={<div> loading...</div>}>{children}</Suspense>;
};

export default PublicRoute;
