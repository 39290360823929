import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { setUserData } from "store/auth/slices";
import { useProfileQuery } from "store/auth/services";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isLoading, data } = useProfileQuery();

  useEffect(() => {
    console.log(data);

    const fetchData = async () => {
      await dispatch(setUserData(data));
      await navigate(location.path);
    };
    if (data) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <div>loading...</div>;

  return <Suspense fallback={<div>loading...</div>}>{children}</Suspense>;
};

export default AuthProvider;
