import { apiSlice, clientLogout } from "store/api";

export const authApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      login: builder.mutation({
        query: (credentials) => ({
          url: "/auth/login",
          method: "POST",
          body: { ...credentials },
        }),
        providesTags: ["auth"],
      }),
      profile: builder.query({
        query() {
          return "/auth/profile";
        },
        providesTags: ["auth"],
      }),
      logout: builder.mutation({
        query: () => ({
          url: "/auth/logout",
          method: "POST",
        }),
        invalidateTags: ["auth"],
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled;
            clientLogout();
          } catch {
            /* empty */
          }
        },
        invalidatesTags: ["auth"],
      }),
    };
  },
});

export const { useLoginMutation, useProfileQuery, useLogoutMutation } = authApi;
