import { Badge, Box, IconButton, styled, useTheme } from "@mui/material";
import PopoverLayout from "./PopoverLayout";
import { useRef, useState } from "react";
import { H6, Tiny, FlexBox, Paragraph } from "components";

import { Notifications } from "@mui/icons-material";
import { useNotificationQuery } from "store/notifications/services";

// styled components

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { data } = useNotificationQuery();

  return (
    <>
      <StyledIconButton ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge color="error" badgeContent={0}>
          <Notifications />
        </Badge>
      </StyledIconButton>

      <PopoverLayout
        hiddenViewButton
        title="Notifications"
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
      >
        {data?.length === 0 ? (
          <Paragraph fontWeight="500" textAlign="center" p={2}>
            There are no notifications
          </Paragraph>
        ) : (
          <>
            {data?.map((msg) => (
              <ListItem msg={msg} key={msg.id} />
            ))}
          </>
        )}
      </PopoverLayout>
    </>
  );
}; // ListItem component props

function ListItem({ msg }) {
  const theme = useTheme();
  const colorbg =
    theme.palette.mode === "light" ? "secondary.light" : "divider";
  return (
    <FlexBox
      p={2}
      alignItems="center"
      sx={{
        borderBottom: 1,
        cursor: "pointer",
        borderColor: "divider",
        backgroundColor: msg.type === "new_message" ? colorbg : "transparent",
        "&:hover": {
          backgroundColor: colorbg,
        },
      }}
    >
      {/* <FlexBox alignItems="center">
        <Box
          sx={{
            width: 8,
            height: 8,
            marginRight: 2,
            borderRadius: "50%",
            backgroundColor:
              msg.type === "new_message" ? "primary.main" : "text.disabled",
          }}
        />
        <AppAvatar
          src={msg.image}
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </FlexBox> */}

      <Box ml={2}>
        <H6>{msg.title}</H6>
        <Tiny display="block" fontWeight={500} color="text.disabled">
          {msg.message}
        </Tiny>
      </Box>
    </FlexBox>
  );
}

export default NotificationsPopover;
