import React, { useState } from 'react';
import { Box, Button, styled, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FlexBox, SearchInput } from "components";

dayjs.extend(utc);

// styled component
const StyledFlexBox = styled(FlexBox)(({ theme }) => ({
  flexWrap: "wrap",
  marginBottom: 20,
  [theme.breakpoints.down(500)]: {
    width: "100%",
    "& .MuiInputBase-root": {
      maxWidth: "100%",
    },
    "& .MuiButton-root": {
      width: "100%",
      marginTop: 15,
    },
  },
}));

const ListHeader = ({
  handleClick,
  setDateValue,
  setSearchValue,
  buttonText = "Ajouter une Réservation",
}) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  return (
    <StyledFlexBox flexDirection="column" alignItems="stretch">
      <FlexBox alignItems="center" justifyContent="space-between" mb={2}>
        {setSearchValue && (
          <Box flexGrow={1} pr={2}>
            <SearchInput
              disable_border
              placeholder="Search..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Box>
        )}

        {setDateValue && (
          <Box flexGrow={2} display="flex" flexDirection="row" alignItems="center" gap={4}>
            <DatePicker
              label="Start Date"
              views={["year", "month", "day"]}
              value={startDate}
              onChange={(newValue) => {
                const newStartDate = dayjs(newValue).startOf('day');
                setStartDate(newStartDate);
                setDateValue({ startDate: newStartDate.format("YYYY-MM-DD"), endDate: endDate.format("YYYY-MM-DD") });
              }}
              renderInput={(params) => <TextField {...params} size="small" fullWidth />}
              sx={{ flexGrow: 1, mr: 0 }}
            />
            <DatePicker
              label="End Date"
              views={["year", "month", "day"]}
              value={endDate}
              onChange={(newValue) => {
                const newEndDate = dayjs(newValue).endOf('day');
                setEndDate(newEndDate);
                setDateValue({ startDate: startDate.format("YYYY-MM-DD"), endDate: newEndDate.format("YYYY-MM-DD") });
              }}
              renderInput={(params) => <TextField {...params} size="small" fullWidth />}
              sx={{ flexGrow: 1, ml: 0 }}
            />
          </Box>
        )}
      </FlexBox>

      {handleClick && (
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" onClick={handleClick}>
            {buttonText}
          </Button>
        </Box>
      )}
    </StyledFlexBox>
  );
};

export default ListHeader;
