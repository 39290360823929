import { Avatar, styled } from "@mui/material";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderColor: theme.palette.common.white,
  borderWidth: 1,
}));

const IconAvatar = (props) => {
  const { children, ...rest } = props;
  return <StyledAvatar {...rest}>{children}</StyledAvatar>;
};

export default IconAvatar;
