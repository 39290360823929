"use client";
import React, { useEffect } from "react";

const Page = ({ id }) => {
  // console.log("id in page", id);
  [...document.getElementsByClassName("table")].forEach((item) => {
    item.setAttribute("fill", "white");
  });
  useEffect(() => {
    // console.log("id in page 2", id);
    if (id) {
      // console.log("id in page 3", id);
      id.forEach((item) => {
        let color;
        switch (item.status) {
          case "confirmed":
            color = "#4caf50";
            break;
          case "pending":
            color = "orange";
            break;
          case "canceled":
            color = "#9e9e9e";
            break;
          case "installed":
            color = "#2196f3";
            break;
          // case "gone":
          //   color = "#b266ff";
          // break;
          default:
            color = "white";
        }
        // console.log("--->", item.tableId, color);
        item.tables.map((item) =>
          document.getElementById(item.id)?.setAttribute("fill", color)
        );
      });
    }
  });
  return (
    <div>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 779.52 543.12"
      >
        <g id="Layer_2" data-name="Layer 2">
          <path
            className="cls-1"
            d="M489.63,80.46c-.6,3.72-1.87,9.13-4.85,14.97-18.87,36.92-79.03,37.11-88.37,37.14-13.64.04-64.12.2-85.39-33.54-4.38-6.94-6.48-13.67-7.58-18.57V4.67h186.19v75.79h0Z"
          />
          <path
            id="3"
            className="table"
            fill="#ffffff"
            d="M261.22,61.08c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="76"
            className="table"
            fill="#ffffff"
            d="M261.22,123.1c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="47"
            className="table"
            fill="#ffffff"
            d="M202.32,167.96c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="75"
            className="table"
            fill="#ffffff"
            d="M187.41,123.1c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="71"
            className="table"
            fill="#ffffff"
            d="M89.85,90.69c-7.57,0-13.73-6.16-13.73-13.73s6.16-13.73,13.73-13.73,13.73,6.16,13.73,13.73-6.16,13.73-13.73,13.73Z"
          />
          <path
            id="17"
            className="table"
            fill="#ffffff"
            d="M137.37,325.66c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="18"
            className="table"
            fill="#ffffff"
            d="M163.8,368.48c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="19"
            className="table"
            fill="#ffffff"
            d="M107.34,368.48c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="25"
            className="table"
            fill="#ffffff"
            d="M398.35,368.07c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.47,15.46-15.47,15.47,6.94,15.47,15.47-6.95,15.47-15.47,15.47Z"
          />
          <path
            id="24"
            className="table"
            fill="#ffffff"
            d="M460.09,368.07c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.47,15.46-15.47,15.47,6.94,15.47,15.47-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="22"
            className="table"
            fill="#ffffff"
            d="M429.92,305.48c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.47,15.47-15.47,15.47,6.94,15.47,15.47-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="23"
            className="table"
            fill="#ffffff"
            d="M498.98,305.48c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.47,15.46-15.47,15.47,6.94,15.47,15.47-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="39"
            className="table"
            fill="#ffffff"
            d="M670.41,393.79c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.47,15.47-15.47,15.47,6.94,15.47,15.47-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="35"
            className="table"
            fill="#ffffff"
            d="M679.65,484.02c-7.02,0-12.73-5.71-12.73-12.73s5.71-12.73,12.73-12.73,12.73,5.71,12.73,12.73-5.71,12.73-12.73,12.73Z"
          />
          <path
            id="36"
            className="table"
            fill="#ffffff"
            d="M618.26,493.94c-7.02,0-12.73-5.71-12.73-12.73s5.71-12.73,12.73-12.73,12.73,5.71,12.73,12.73-5.71,12.73-12.73,12.73Z"
          />
          <path
            id="20"
            className="table"
            fill="#ffffff"
            d="M296.02,305.48c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.47,15.46-15.47,15.47,6.94,15.47,15.47-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="21"
            className="table"
            fill="#ffffff"
            d="M363.43,305.48c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.47,15.46-15.47,15.47,6.94,15.47,15.47-6.95,15.47-15.47,15.47Z"
          />
          <path
            id="44"
            className="table"
            fill="#ffffff"
            d="M348.34,247.72c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.46,15.47-15.46,15.46,6.94,15.46,15.46c.01,8.53-6.93,15.47-15.46,15.47Z"
          />
          <path
            id="45"
            className="table"
            fill="#ffffff"
            d="M302.8,247.72c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.46,15.46-15.46,15.47,6.94,15.47,15.46c0,8.53-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="46"
            className="table"
            fill="#ffffff"
            d="M256.82,247.72c-8.53,0-15.46-6.94-15.46-15.47s6.94-15.46,15.46-15.46,15.47,6.94,15.47,15.46c-.01,8.53-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="43"
            className="table"
            fill="#ffffff"
            d="M393.6,247.72c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.46,15.47-15.46,15.47,6.94,15.47,15.46c-.01,8.53-6.95,15.47-15.47,15.47Z"
          />
          <path
            id="42"
            className="table"
            fill="#ffffff"
            d="M439.07,247.72c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.46,15.47-15.46,15.47,6.94,15.47,15.46-6.94,15.47-15.47,15.47Z"
          />
          <path
            id="41"
            className="table"
            fill="#ffffff"
            d="M484.8,247.72c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.46,15.47-15.46,15.46,6.94,15.46,15.46c0,8.53-6.93,15.47-15.46,15.47Z"
          />
          <path
            id="40"
            className="table"
            fill="#ffffff"
            d="M530.78,247.72c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.46,15.47-15.46,15.47,6.94,15.47,15.46c-.01,8.53-6.95,15.47-15.47,15.47Z"
          />
          <path
            id="50"
            className="table"
            fill="#ffffff"
            d="M653.73,274.85c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.29,14-14,14Z"
          />
          <path
            id="37"
            className="table"
            fill="#ffffff"
            d="M526.21,493.95c-10.87,0-19.71-8.84-19.71-19.71s8.84-19.71,19.71-19.71,19.71,8.84,19.71,19.71c0,10.87-8.84,19.71-19.71,19.71Z"
          />
          <path
            id="32"
            className="table"
            fill="#ffffff"
            d="M741.2,337.83c-10.42,0-18.9-8.48-18.9-18.9s8.48-18.9,18.9-18.9,18.9,8.48,18.9,18.9c0,10.42-8.48,18.9-18.9,18.9Z"
          />
          <path
            id="51"
            className="table"
            fill="#ffffff"
            d="M720.8,274.85c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="52"
            className="table"
            fill="#ffffff"
            d="M720.8,239.42c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="53"
            className="table"
            fill="#ffffff"
            d="M720.8,204.74c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="54"
            className="table"
            fill="#ffffff"
            d="M653.73,203.61c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.29,14-14,14Z"
          />
          <path
            id="60"
            className="table"
            fill="#ffffff"
            d="M653.73,162.87c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.29,14-14,14Z"
          />
          <path
            id="61"
            className="table"
            fill="#ffffff"
            d="M720.8,162.87c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="62"
            className="table"
            fill="#ffffff"
            d="M720.04,127.42c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="63"
            className="table"
            fill="#ffffff"
            d="M720.04,91.98c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.28,14-14,14Z"
          />
          <path
            id="64"
            className="table"
            fill="#ffffff"
            d="M653.73,91.98c-7.72,0-14-6.28-14-14s6.28-14,14-14,14,6.28,14,14-6.29,14-14,14Z"
          />
          <path
            id="26"
            className="table"
            fill="#ffffff"
            d="M336.34,368.07c-8.53,0-15.47-6.94-15.47-15.47s6.94-15.47,15.47-15.47,15.46,6.94,15.46,15.47-6.93,15.47-15.46,15.47Z"
          />
          <path
            id="16"
            className="table"
            fill="#ffffff"
            d="M33.16,326.2c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08c0,7.77-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="15"
            className="table"
            fill="#ffffff"
            d="M32.3,360.82c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08c0,7.76-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="15b"
            className="table"
            fill="#ffffff"
            d="M32.73,394.16c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08c0,7.76-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="14"
            className="table"
            fill="#ffffff"
            d="M31.87,428.77c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="12"
            className="table"
            fill="#ffffff"
            d="M32.59,460.38c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="80"
            className="table"
            fill="#ffffff"
            d="M70.58,460.38c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="11"
            className="table"
            fill="#ffffff"
            d="M109.99,460.38c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="10"
            className="table"
            fill="#ffffff"
            d="M147.98,460.38c-7.77,0-14.08-6.32-14.08-14.08s6.32-14.08,14.08-14.08,14.08,6.32,14.08,14.08-6.32,14.08-14.08,14.08Z"
          />
          <path
            id="70"
            className="table"
            fill="#ffffff"
            d="M35.53,90.69c-7.57,0-13.73-6.16-13.73-13.73s6.16-13.73,13.73-13.73,13.73,6.16,13.73,13.73-6.16,13.73-13.73,13.73Z"
          />
          <path
            id="2"
            className="table"
            fill="#ffffff"
            d="M224.28,61.08c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="1"
            className="table"
            fill="#ffffff"
            d="M186.6,61.08c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="6"
            className="table"
            fill="#ffffff"
            d="M600.46,56.82c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="5"
            className="table"
            fill="#ffffff"
            d="M563.51,56.82c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="66"
            className="table"
            fill="#ffffff"
            d="M525.84,117.98c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="65"
            className="table"
            fill="#ffffff"
            d="M600.46,119.49c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="48"
            className="table"
            fill="#ffffff"
            d="M578.42,162.75c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="4"
            className="table"
            fill="#ffffff"
            d="M525.84,56.82c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="93"
            className="table"
            fill="#ffffff"
            d="M28.45,243.16c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="85"
            className="table"
            fill="#ffffff"
            d="M749.2,498.66c-7.02,0-12.73-5.71-12.73-12.73s5.71-12.73,12.73-12.73,12.73,5.71,12.73,12.73-5.72,12.73-12.73,12.73Z"
          />
          <path
            id="38"
            className="table"
            fill="#ffffff"
            d="M601.8,445h-24v-25.51h24v25.51Z"
          />
          <path
            id="33"
            className="table"
            fill="#ffffff"
            d="M760,407.82h-28.81v-37.55h28.81v37.55Z"
          />
          <path
            id="30"
            className="table"
            fill="#ffffff"
            d="M649.13,349.25h-27.71v-54.56h27.71v54.56Z"
          />
          <path
            id="31"
            className="table"
            fill="#ffffff"
            d="M702.3,323.57h-27.57v-27.57h27.57v27.57Z"
          />
          <path
            id="91"
            className="table"
            fill="#ffffff"
            d="M103.24,286.9c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="90"
            className="table"
            fill="#ffffff"
            d="M147.65,286.9c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.85,15.28-15.28,15.28Z"
          />
          <path
            id="92"
            className="table"
            fill="#ffffff"
            d="M54.98,286.9c-8.43,0-15.28-6.85-15.28-15.28s6.85-15.28,15.28-15.28,15.28,6.85,15.28,15.28-6.86,15.28-15.28,15.28Z"
          />
          <path
            id="34"
            className="table"
            fill="#ffffff"
            d="M760,452.38h-28.81v-37.55h28.81v37.55Z"
          />
          <rect
            className="cls-1"
            x="734.98"
            y="460.94"
            width="6.52"
            height="6.12"
            transform="translate(-130.96 533.14) rotate(-36.7)"
          />
          <rect
            className="cls-1"
            x="715.22"
            y="484.86"
            width="6.52"
            height="6.12"
            transform="translate(-16.71 950.29) rotate(-66.35)"
          />
          <rect
            className="cls-1"
            x="605.56"
            y="400.48"
            width="14.26"
            height="14.26"
          />
          <polygon
            className="cls-1"
            points="774.22 538.6 720.7 516.37 476.5 520.16 476.5 495.67 499.25 495.29 498.49 492.11 499.46 491.88 500.51 496.27 477.5 496.65 477.5 519.15 720.9 515.37 720.99 515.41 773.21 537.1 771.33 283.69 740.75 283.69 740.75 55.38 632.18 55.38 632.18 54.38 741.75 54.38 741.75 282.69 772.32 282.69 772.32 283.19 774.22 538.6"
          />
          <polygon
            className="cls-1"
            points="137.37 483.4 8.12 483.4 8.12 56.84 116.62 56.84 116.62 57.84 9.12 57.84 9.12 482.4 137.37 482.4 137.37 483.4"
          />
          <rect
            className="cls-1"
            x="8.62"
            y="156.88"
            width="108.76"
            height="1"
          />
          <rect
            className="cls-1"
            x="8.62"
            y="289.51"
            width="159.16"
            height="1"
          />
          <polygon
            className="cls-1"
            points="280.45 520.29 279.45 520.29 279.45 496.28 257.21 496.28 257.21 495.28 280.45 495.28 280.45 520.29"
          />
          <g>
            <polygon
              className="cls-1"
              points="562.88 400.43 562.29 399.62 567.15 396.09 567.74 396.9 562.88 400.43"
            />
            <path
              className="cls-1"
              d="M512.09,437.26l-.59-.81,8.46-6.14.59.81-8.46,6.14ZM529.02,424.98l-.59-.81,8.46-6.14.59.81-8.46,6.14ZM545.95,412.7l-.59-.81,8.46-6.14.59.81-8.46,6.14Z"
            />
            <polygon
              className="cls-1"
              points="498.97 452.52 497.97 452.52 497.97 446.27 503.04 442.59 503.62 443.4 498.97 446.78 498.97 452.52"
            />
            <rect
              className="cls-1"
              x="497.97"
              y="461.66"
              width="1"
              height="9.14"
            />
            <rect
              className="cls-1"
              x="497.97"
              y="479.93"
              width="1"
              height="6"
            />
          </g>
          <rect
            className="cls-1"
            x="241.73"
            y="256.29"
            width="303.86"
            height="1"
          />
          <g>
            <path
              className="cls-2"
              d="M180.83,17.38l.94.02c.83,0,1.53-.22,2.11-.65s.91-1.02.99-1.75c.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76-.01-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39s1.61-.92,2.63-.91c.98.02,1.74.34,2.29.94.55.6.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54-.75.65-1.67.96-2.75.94-1.02-.02-1.83-.35-2.45-.98s-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82.43.48,1.03.73,1.79.76.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67s-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M190.55,24.06c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.32-1.14.48-1.81.47ZM193.58,19.31l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M145.31,470.03h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM140.1,470.03h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M151.76,474.05c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.48-1.81.47ZM154.79,469.3l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M221.68,23.89h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M227.31,24.06c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM230.33,19.31l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M563,18.84h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M568.63,19.01c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM571.65,14.26l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M261.26,23.89h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M266.89,24.06c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.14-12.31h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.55.33-1.15.49-1.82.48ZM269.91,19.31l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M523.38,14.99h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM518.16,14.99h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M529.83,19.01c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM532.85,14.26l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M599.01,14.99h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM593.8,14.99h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M605.46,19.01c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM608.48,14.26l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M342.77,203.05l.94.02c.83,0,1.53-.22,2.11-.65s.91-1.02.99-1.75c.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76,0-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39s1.61-.92,2.63-.91c.98.02,1.74.34,2.29.94.55.6.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98-.62-.63-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82.43.48,1.03.73,1.79.76.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67s-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M352.49,209.73c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM355.51,204.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M433.2,203.05l.94.02c.83,0,1.53-.22,2.11-.65s.91-1.02.99-1.75c.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76,0-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39s1.61-.92,2.63-.91c.98.02,1.74.34,2.29.94.55.6.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98-.62-.63-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82.43.48,1.03.73,1.79.76.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67-.39-.42-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M442.92,209.73c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.15.48-1.81.47ZM445.94,204.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M693.13,149.83h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM687.91,149.83h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M699.58,153.85c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM702.6,149.1l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M282.2,110.09l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.74.37,2.27,1.09s.75,1.61.65,2.7c-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95s-.82-1.4-.82-2.38h.93c.01.77.21,1.38.59,1.81s.93.66,1.63.69c.83.02,1.51-.26,2.04-.85s.83-1.37.92-2.35c.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.8-.25Z"
            />
            <path
              className="cls-2"
              d="M293.51,116.4c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.21,1.25c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.55.34-1.16.5-1.83.48ZM296.54,111.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M494.6,112.98l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.74.37,2.27,1.09.53.71.75,1.61.65,2.7-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95s-.83-1.4-.83-2.38h.93c.01.77.21,1.38.59,1.81.38.44.93.66,1.63.69.83.02,1.51-.26,2.04-.85s.83-1.37.92-2.35c.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.79-.25Z"
            />
            <path
              className="cls-2"
              d="M505.92,119.29c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.15.49-1.81.47ZM508.94,114.54l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M575.15,105.55l-.1.85h-.26c-1.3.03-2.4.45-3.29,1.26-.89.81-1.55,1.98-1.97,3.51.83-.97,1.85-1.44,3.06-1.42.63.01,1.17.18,1.62.52s.78.8.99,1.4c.21.6.28,1.25.21,1.95-.05.56-.18,1.1-.41,1.62-.22.52-.51.98-.87,1.36-.74.8-1.64,1.19-2.7,1.17-.91-.03-1.64-.35-2.2-.96s-.88-1.4-.96-2.38c-.05-.5.02-1.29.2-2.37.34-2.04,1.08-3.64,2.21-4.79,1.14-1.15,2.59-1.73,4.35-1.73h.12ZM572.39,110.61c-.65-.01-1.27.17-1.88.55s-.99.85-1.15,1.4l-.11.85c-.07.63-.03,1.21.11,1.74.15.53.4.96.76,1.28s.81.49,1.34.5c.52.01.99-.12,1.42-.41.42-.28.77-.67,1.04-1.18.27-.5.44-1.07.51-1.69.09-.88-.05-1.61-.42-2.18-.35-.55-.9-.85-1.62-.86Z"
            />
            <path
              className="cls-2"
              d="M580.54,117.78c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM583.56,113.03l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M211.92,104.18l-.1.85h-.26c-1.3.03-2.4.45-3.29,1.26-.89.81-1.55,1.98-1.97,3.51.83-.97,1.85-1.44,3.06-1.42.63.01,1.17.18,1.62.52s.78.8.98,1.4c.21.6.28,1.25.21,1.95-.05.56-.19,1.1-.41,1.62-.22.52-.51.98-.87,1.36-.74.8-1.65,1.19-2.7,1.17-.91-.03-1.64-.35-2.2-.96s-.88-1.4-.96-2.38c-.05-.5.02-1.29.2-2.37.34-2.04,1.08-3.64,2.21-4.79,1.14-1.15,2.59-1.73,4.35-1.73h.13ZM209.15,109.24c-.65-.01-1.27.17-1.88.55-.6.38-.99.85-1.15,1.4l-.11.85c-.07.63-.03,1.21.11,1.74.15.53.4.96.76,1.28s.81.49,1.34.5c.52.01.99-.12,1.42-.41.42-.28.77-.67,1.04-1.18.27-.5.44-1.07.51-1.69.09-.88-.05-1.61-.41-2.18-.36-.55-.9-.85-1.63-.86Z"
            />
            <path
              className="cls-2"
              d="M217.31,116.4c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.15.49-1.81.47ZM220.33,111.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M254.08,204.7h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM248.87,204.7h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M260.53,208.73c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.32-1.14.48-1.81.47ZM263.55,203.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M146.94,243.49h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM141.72,243.49h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M153.39,247.51c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM156.41,242.76l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M529.27,204.7h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM524.06,204.7h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M535.73,208.73c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.15.48-1.81.47ZM538.75,203.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M688.27,265.42l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.73.37,2.27,1.09s.75,1.61.65,2.7c-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95-.55-.61-.83-1.4-.83-2.38h.93c.01.77.21,1.38.59,1.81s.93.66,1.63.69c.83.02,1.51-.26,2.04-.85.53-.59.83-1.37.92-2.35.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.79-.25Z"
            />
            <path
              className="cls-2"
              d="M699.58,271.73c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM702.6,266.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M619.04,265.48l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.74.37,2.27,1.09.53.71.75,1.61.65,2.7-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95-.55-.61-.82-1.4-.82-2.38h.93c.01.77.21,1.38.59,1.81.38.44.93.66,1.63.69.83.02,1.51-.26,2.04-.85.53-.59.83-1.37.92-2.35.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.8-.25Z"
            />
            <path
              className="cls-2"
              d="M630.36,271.79c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM633.38,267.04l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M617.02,191.09l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98,0,1.74.37,2.27,1.09.53.71.75,1.61.65,2.7-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95-.55-.61-.82-1.4-.82-2.38h.93c.01.77.21,1.38.59,1.81.38.44.93.66,1.63.69.83.02,1.51-.26,2.04-.85.53-.59.83-1.37.92-2.35.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.8-.25Z"
            />
            <path
              className="cls-2"
              d="M628.34,197.4c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.15.49-1.81.47ZM631.36,192.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M693.13,79.78h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM687.91,79.78h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M699.58,83.8c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.22,1.25c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.55.34-1.15.5-1.82.48ZM702.6,79.05l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M222.51,163.35h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M230.71,163.51c-.64-.02-1.18-.19-1.63-.51s-.77-.79-.97-1.42-.29-1.31-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8.73-.87,1.69-1.29,2.88-1.26.97.02,1.69.38,2.18,1.07s.71,1.66.68,2.9c0,.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85s-1.68,1.25-2.81,1.21ZM234.53,154.45c-.11-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02-.56.71-.95,1.74-1.15,3.08l-.36,2.31c-.08.66-.11,1.25-.07,1.77.04.78.23,1.38.57,1.8s.8.63,1.38.65c.82.04,1.51-.27,2.07-.93.56-.66.96-1.63,1.19-2.93l.39-2.52c.1-.67.14-1.29.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M240.17,163.52c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.15.48-1.81.47ZM243.19,158.77l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M355.84,322.63h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M364.04,322.79c-.64-.02-1.18-.19-1.63-.51-.44-.32-.77-.79-.97-1.42s-.29-1.32-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8.73-.87,1.69-1.29,2.88-1.26.97.02,1.69.38,2.18,1.07s.71,1.66.68,2.9c-.01.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85-.74.85-1.68,1.25-2.81,1.21ZM367.85,313.73c-.11-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02-.56.71-.95,1.74-1.15,3.08l-.36,2.32c-.08.66-.11,1.25-.07,1.76.04.78.23,1.38.57,1.8s.8.63,1.38.65c.82.04,1.51-.27,2.07-.93s.96-1.63,1.19-2.93l.39-2.52c.11-.66.15-1.28.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M373.49,322.8c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.7-.84,1.21-1.38,1.56s-1.14.49-1.81.47ZM376.52,318.05l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M422.33,322.63h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M430.53,322.79c-.64-.02-1.18-.19-1.62-.51s-.77-.79-.97-1.42c-.2-.62-.29-1.31-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8s1.69-1.29,2.88-1.26c.97.02,1.7.38,2.18,1.07s.71,1.66.68,2.9c-.01.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85-.75.85-1.69,1.25-2.82,1.21ZM434.35,313.73c-.11-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02s-.95,1.74-1.15,3.08l-.36,2.31c-.08.66-.11,1.25-.07,1.77.04.78.23,1.38.57,1.8.34.42.8.63,1.38.65.82.04,1.51-.27,2.07-.93s.96-1.63,1.19-2.93l.39-2.52c.1-.66.14-1.29.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M439.99,322.8c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.15.49-1.81.47ZM443.01,318.05l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M540.84,162.53h-.99l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.04,12Z"
            />
            <path
              className="cls-2"
              d="M549.04,162.69c-.64-.02-1.18-.19-1.63-.51s-.77-.79-.97-1.42c-.2-.62-.29-1.31-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8.73-.87,1.69-1.29,2.88-1.26.97.02,1.69.38,2.18,1.07s.71,1.66.68,2.9c-.01.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85-.74.85-1.68,1.25-2.81,1.21ZM552.85,153.63c-.12-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02-.56.71-.95,1.74-1.15,3.08l-.36,2.31c-.08.66-.11,1.25-.07,1.77.04.78.23,1.38.57,1.8s.8.63,1.38.65c.82.04,1.51-.27,2.07-.93s.96-1.63,1.19-2.93l.39-2.52c.11-.67.15-1.29.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M558.49,162.7c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.32-1.14.49-1.81.47ZM561.52,157.95l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M675.93,402.2c-.06.65-.3,1.21-.71,1.7s-.97.87-1.68,1.15c.58.27,1.02.65,1.31,1.16.3.5.42,1.07.38,1.7-.08,1.02-.51,1.85-1.29,2.49-.78.64-1.74.94-2.87.92-1.03-.02-1.84-.33-2.45-.92-.6-.59-.86-1.37-.78-2.35.06-.7.32-1.31.79-1.83s1.1-.94,1.9-1.23c-.49-.26-.86-.63-1.1-1.09s-.34-.98-.3-1.54c.07-.99.47-1.79,1.19-2.41.72-.62,1.6-.93,2.65-.9.94.02,1.69.32,2.24.91s.8,1.32.72,2.24ZM674.26,407.92c.08-.71-.1-1.29-.54-1.76s-1.02-.71-1.75-.73c-.84-.01-1.56.24-2.15.74s-.92,1.14-1.01,1.9c-.08.72.09,1.3.52,1.74.43.45,1.02.67,1.77.69.83.02,1.54-.21,2.13-.69.61-.48.94-1.11,1.03-1.89ZM674.95,402.2c.07-.64-.09-1.18-.47-1.63-.38-.45-.9-.68-1.56-.7-.75-.01-1.39.22-1.91.69-.52.47-.82,1.07-.9,1.79-.07.65.09,1.18.47,1.61.38.43.9.65,1.56.66.73.02,1.36-.2,1.9-.66.54-.47.84-1.06.91-1.76Z"
            />
            <path
              className="cls-2"
              d="M680.48,411.32c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM683.5,406.58l.05-.78c.02-.88-.14-1.56-.48-2.06s-.83-.76-1.49-.78c-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M626.2,337.52h-.99l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.04,12Z"
            />
            <path
              className="cls-2"
              d="M634.4,337.68c-.64-.02-1.18-.19-1.63-.51-.44-.32-.77-.79-.97-1.42-.2-.62-.29-1.32-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8.73-.87,1.69-1.29,2.88-1.26.97.02,1.69.38,2.18,1.07s.71,1.66.68,2.9c-.01.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85-.74.85-1.67,1.25-2.81,1.21ZM638.22,328.62c-.12-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02-.56.71-.95,1.74-1.15,3.08l-.36,2.32c-.08.66-.11,1.25-.07,1.76.04.78.23,1.38.57,1.8.34.42.8.63,1.38.65.82.04,1.51-.27,2.07-.93s.96-1.63,1.19-2.93l.39-2.52c.11-.66.14-1.29.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M643.86,337.69c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM646.88,332.94l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M491.39,322.63h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M503.42,322.63h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7-.36-.45-.89-.68-1.6-.71-.77-.02-1.43.22-1.98.73-.55.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M509.05,322.8c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.15.49-1.81.47ZM512.07,318.05l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M286.73,322.63h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M298.76,322.63h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7-.36-.45-.9-.68-1.6-.71-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M304.39,322.8c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.7-.84,1.21-1.38,1.56s-1.14.49-1.81.47ZM307.41,318.05l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M389.01,386.22h-.98l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.05,12Z"
            />
            <path
              className="cls-2"
              d="M401.04,386.22h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7-.36-.45-.9-.68-1.6-.71-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M406.67,386.4c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM409.69,381.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.72,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.06-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M553.1,483.71h-.99l1.87-10.74-3.37,1.2.17-.97,4.17-1.49h.19l-2.04,12Z"
            />
            <path
              className="cls-2"
              d="M561.3,483.87c-.64-.02-1.18-.19-1.63-.51-.44-.32-.77-.79-.97-1.42-.2-.62-.29-1.31-.27-2.08l.06-.89.35-2.33c.26-1.66.75-2.93,1.49-3.8s1.69-1.29,2.88-1.26c.97.02,1.69.38,2.18,1.07s.71,1.66.68,2.9c-.01.39-.04.77-.09,1.14l-.33,2.12c-.29,1.74-.8,3.02-1.54,3.85-.74.85-1.67,1.25-2.81,1.21ZM565.12,474.81c-.12-1.55-.77-2.35-1.95-2.39-.89-.03-1.61.31-2.18,1.02-.56.71-.95,1.74-1.15,3.08l-.36,2.31c-.08.66-.11,1.25-.07,1.77.04.78.23,1.38.57,1.8.34.42.8.63,1.38.65.82.04,1.51-.27,2.07-.93s.96-1.63,1.19-2.93l.39-2.52c.11-.67.14-1.29.11-1.86Z"
            />
            <path
              className="cls-2"
              d="M570.76,483.88c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.49-1.81.47ZM573.78,479.13l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M745.08,343.66c-.06.65-.3,1.21-.71,1.7-.41.48-.97.87-1.68,1.15.58.27,1.02.65,1.31,1.16.29.5.42,1.07.38,1.7-.08,1.02-.51,1.85-1.29,2.49s-1.73.94-2.87.92c-1.03-.02-1.84-.33-2.45-.92s-.86-1.37-.78-2.35c.06-.7.32-1.31.79-1.83.46-.53,1.1-.94,1.9-1.23-.49-.26-.86-.63-1.1-1.09s-.34-.98-.3-1.54c.07-.99.47-1.79,1.19-2.41s1.6-.92,2.65-.9c.94.02,1.69.32,2.24.91.56.56.8,1.31.72,2.24ZM743.41,349.37c.08-.71-.1-1.29-.54-1.76-.43-.46-1.02-.71-1.75-.73-.84-.01-1.56.24-2.15.74s-.92,1.14-1.01,1.9c-.08.72.09,1.3.52,1.75s1.02.67,1.77.69c.83.02,1.54-.21,2.13-.69.61-.49.94-1.12,1.03-1.9ZM744.1,343.65c.07-.64-.09-1.18-.47-1.63-.38-.45-.9-.68-1.55-.7-.76-.01-1.39.22-1.91.69-.52.47-.82,1.07-.9,1.79-.07.65.09,1.18.47,1.61s.9.65,1.56.66c.73.02,1.36-.2,1.9-.66.53-.47.83-1.06.9-1.76Z"
            />
            <path
              className="cls-2"
              d="M749.63,352.78c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM752.65,348.03l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.18.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M62.59,303l-.1.85h-.26c-1.3.03-2.4.45-3.29,1.26s-1.55,1.98-1.97,3.51c.83-.97,1.85-1.44,3.06-1.42.63.01,1.17.18,1.62.52s.78.8.98,1.4c.21.6.28,1.25.21,1.95-.05.56-.19,1.1-.41,1.62s-.51.98-.87,1.36c-.74.8-1.65,1.19-2.7,1.17-.91-.03-1.64-.35-2.2-.96s-.88-1.4-.96-2.38c-.05-.5.02-1.29.2-2.37.34-2.04,1.08-3.64,2.21-4.79,1.14-1.15,2.59-1.73,4.35-1.73h.13ZM59.83,308.06c-.65-.01-1.27.17-1.88.55-.6.38-.99.85-1.15,1.4l-.11.85c-.07.63-.03,1.21.11,1.74.15.53.4.96.76,1.28.36.32.81.49,1.34.5.52.01.99-.12,1.42-.41.42-.28.77-.67,1.04-1.18.27-.5.44-1.07.51-1.69.09-.88-.05-1.61-.41-2.18-.36-.55-.91-.85-1.63-.86Z"
            />
            <path
              className="cls-2"
              d="M67.98,315.22c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM71,310.47l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M59.93,351.36h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M65.56,351.53c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM68.59,346.78l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M392.42,208.56h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M398.05,208.73c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM401.07,203.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M689.86,109.72l.94.02c.83,0,1.53-.22,2.11-.65.58-.43.91-1.02.99-1.75.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76-.01-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39.73-.62,1.61-.92,2.63-.91.98.02,1.74.34,2.29.94.55.6.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98-.62-.63-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82s1.03.73,1.79.76c.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67-.39-.42-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M699.58,116.4c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM702.6,111.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M694.74,197.23h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.89-.68-1.6-.71c-.77-.02-1.43.22-1.98.73s-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M700.37,197.4c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM703.39,192.65l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M689.86,225.74l.94.02c.83,0,1.53-.22,2.11-.65.58-.43.91-1.02.99-1.75.08-.72-.07-1.3-.44-1.72s-.91-.65-1.61-.67c-.76,0-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39.73-.62,1.61-.92,2.63-.91.98.02,1.74.34,2.29.94s.78,1.38.69,2.33c-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98-.62-.63-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82.43.48,1.03.73,1.79.76.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67s-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M699.58,232.43c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM702.6,227.68l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M614.71,437.17h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.89-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M620.34,437.34c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM623.36,432.6l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M59.93,384.13h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M65.56,384.3c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM68.59,379.55l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M301.3,209.56h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M306.93,209.73c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM309.95,204.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M26.93,202.88h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M32.56,203.05c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM35.59,198.31l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M56.29,247.34h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M61.93,247.51c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.15.49-1.81.47ZM64.95,242.76l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M104.83,247.34h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M110.46,247.51c-.51,0-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM113.48,242.76l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M482.85,208.56h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M488.48,208.73c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM491.5,203.98l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57,0-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M688.45,341.17h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.89-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M694.08,341.34c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM697.11,336.6l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M715.58,394.72h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.89-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M721.21,394.89c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM724.24,390.15l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M715.58,435.6h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.3-2.61.74-.68,1.64-1.01,2.7-1,.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.13.81Z"
            />
            <path
              className="cls-2"
              d="M721.21,435.77c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM724.24,431.03l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M647.45,101.31l.94.02c.83,0,1.53-.22,2.11-.65.58-.43.91-1.02.99-1.75.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76-.01-1.39.21-1.92.66s-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39.73-.62,1.61-.92,2.63-.91.98.02,1.74.34,2.29.94.55.6.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61s-.98.87-1.71,1.16c.6.21,1.05.57,1.36,1.06.3.49.43,1.07.37,1.72-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98-.62-.63-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82s1.03.73,1.79.76c.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67-.39-.42-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.86Z"
            />
            <path
              className="cls-2"
              d="M657.17,108c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.32-1.14.48-1.81.47ZM660.2,103.25l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42s-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M629.43,159.18h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.89-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92s.77,1.34.68,2.25c-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M635.06,159.35c-.51,0-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM638.08,154.6l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58,0-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M619.21,504.19h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM613.99,504.19h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M625.66,508.21c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.48-1.81.47ZM628.68,503.46l.05-.78c.02-.88-.14-1.56-.48-2.06s-.83-.76-1.49-.78c-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M60.31,417.07h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M65.94,417.24c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM68.96,412.49l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M24.49,467.73l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.74.37,2.27,1.09.53.71.75,1.61.65,2.7-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95-.55-.61-.82-1.4-.82-2.38h.93c.01.77.21,1.38.59,1.81.38.43.93.66,1.63.69.83.02,1.51-.26,2.04-.85.53-.59.83-1.37.92-2.35.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.8-.25Z"
            />
            <path
              className="cls-2"
              d="M35.81,474.05c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.15.48-1.81.47ZM38.83,469.3l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M68.07,473.88h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M73.7,474.05c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.48-1.81.47ZM76.72,469.3l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M108.62,473.88h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.5l-4.7,4.63h6.07l-.14.82Z"
            />
            <path
              className="cls-2"
              d="M114.25,474.05c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56s-1.14.48-1.81.47ZM117.27,469.3l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M104.83,384.13h-7.28l.12-.8,5.17-5.11c1.09-1.1,1.69-2.09,1.79-2.97.09-.68-.05-1.25-.41-1.7s-.9-.68-1.6-.71c-.77-.02-1.43.22-1.98.73-.56.51-.9,1.19-1.05,2.05h-.94c.13-1.06.57-1.93,1.31-2.61s1.64-1.01,2.7-1c.99.02,1.76.33,2.3.92.54.59.77,1.34.68,2.25-.11,1.08-.79,2.25-2.04,3.51l-4.7,4.63h6.07l-.14.81Z"
            />
            <path
              className="cls-2"
              d="M110.46,384.3c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM113.48,379.55l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M32.36,103.97h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.44l.07-.55,6.79-8.38h1.1l-1.4,8.1ZM27.14,103.97h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M38.81,108c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM41.83,103.25l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M87.59,103.97h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM82.38,103.97h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M94.04,108c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.32-1.14.48-1.81.47ZM97.06,103.25l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89s.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M156.75,311.87l1.46-5.81h5.84l-.15.91h-4.97l-1.08,4.04c.65-.44,1.4-.66,2.23-.65.98.01,1.74.37,2.27,1.09.53.71.75,1.61.65,2.7-.11,1.22-.52,2.2-1.24,2.95s-1.63,1.11-2.75,1.09c-.97-.02-1.73-.34-2.28-.95-.55-.61-.82-1.4-.82-2.38h.93c0,.77.21,1.38.59,1.81.38.43.93.66,1.63.69.83.02,1.51-.26,2.04-.85s.83-1.37.92-2.35c.08-.84-.08-1.53-.46-2.06s-.94-.81-1.68-.83c-.84-.02-1.61.26-2.33.85l-.8-.25Z"
            />
            <path
              className="cls-2"
              d="M168.06,318.19c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.32-1.14.48-1.81.47ZM171.08,313.44l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M334.81,375.18c-.06.65-.3,1.21-.71,1.7s-.97.87-1.68,1.15c.58.27,1.02.65,1.31,1.16.3.5.42,1.07.38,1.7-.08,1.02-.51,1.85-1.29,2.49-.78.63-1.74.94-2.87.92-1.03-.02-1.84-.33-2.45-.92s-.86-1.37-.78-2.35c.06-.7.32-1.31.79-1.83.46-.53,1.1-.94,1.9-1.23-.49-.26-.86-.63-1.1-1.09s-.34-.98-.3-1.54c.07-.99.47-1.79,1.19-2.41s1.6-.92,2.65-.9c.94.02,1.69.32,2.24.91.55.56.79,1.32.72,2.24ZM333.13,380.9c.08-.71-.1-1.29-.54-1.76-.44-.46-1.02-.71-1.74-.73-.84-.01-1.56.24-2.15.74s-.92,1.14-1.01,1.9c-.08.72.09,1.3.52,1.75s1.02.67,1.77.69c.83.02,1.54-.21,2.13-.69.6-.49.94-1.12,1.02-1.9ZM333.82,375.17c.07-.64-.09-1.18-.47-1.63s-.9-.68-1.56-.7c-.76-.01-1.39.22-1.91.69s-.82,1.07-.9,1.79c-.07.65.09,1.18.47,1.61s.9.65,1.56.66c.73.02,1.36-.2,1.9-.66.54-.47.84-1.05.91-1.76Z"
            />
            <path
              className="cls-2"
              d="M339.35,384.3c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.53.33-1.14.49-1.81.47ZM342.37,379.55l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M462.1,375.18c-.06.65-.3,1.21-.71,1.7s-.97.87-1.68,1.15c.58.27,1.02.65,1.31,1.16.29.5.42,1.07.38,1.7-.08,1.02-.51,1.85-1.29,2.49-.78.64-1.74.94-2.87.92-1.03-.02-1.84-.33-2.45-.92-.6-.59-.86-1.37-.78-2.35.06-.7.32-1.31.79-1.83.46-.53,1.1-.94,1.9-1.23-.49-.26-.86-.63-1.1-1.09-.24-.46-.34-.98-.29-1.54.07-.99.47-1.79,1.19-2.41s1.6-.93,2.65-.9c.94.02,1.69.32,2.24.91.55.56.79,1.32.71,2.24ZM460.43,380.9c.08-.71-.1-1.29-.54-1.76-.43-.47-1.02-.71-1.74-.73-.84-.01-1.56.24-2.15.74s-.92,1.14-1.01,1.9c-.08.72.09,1.3.52,1.74.43.45,1.02.67,1.77.69.83.02,1.54-.21,2.13-.69.6-.48.94-1.11,1.02-1.89ZM461.12,375.17c.07-.64-.09-1.18-.47-1.63s-.9-.68-1.56-.7c-.76-.01-1.39.22-1.91.69s-.82,1.07-.89,1.79c-.07.65.09,1.18.47,1.61s.9.65,1.56.66c.73.02,1.36-.2,1.9-.66.53-.47.83-1.05.9-1.76Z"
            />
            <path
              className="cls-2"
              d="M466.65,384.3c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.49-1.81.47ZM469.67,379.55l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M677.05,496.63h1.81l-.15.83h-1.8l-.53,3.02h-.98l.53-3.02h-5.43l.07-.55,6.79-8.38h1.1l-1.41,8.1ZM671.83,496.63h4.23l1.18-6.73-.51.68-4.9,6.05Z"
            />
            <path
              className="cls-2"
              d="M683.5,500.66c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54.01.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48s-.84,1.21-1.38,1.56-1.14.48-1.81.47ZM686.52,495.91l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.58-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.27l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.42Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M744.33,508.09l.94.02c.83,0,1.53-.22,2.11-.65.58-.43.91-1.02.99-1.75.08-.72-.07-1.3-.44-1.72-.37-.43-.91-.65-1.61-.67-.76-.01-1.39.21-1.92.66-.52.45-.85,1.05-.99,1.81h-.95c.12-.97.55-1.77,1.28-2.39s1.61-.92,2.63-.91c.98.02,1.74.33,2.29.94.55.61.78,1.38.69,2.33-.05.59-.28,1.13-.69,1.61-.41.48-.98.87-1.71,1.16.6.21,1.05.57,1.36,1.06s.43,1.07.37,1.72c-.09,1.05-.51,1.89-1.26,2.54s-1.67.96-2.75.94c-1.02-.02-1.83-.35-2.45-.98s-.89-1.44-.81-2.43h.97c-.07.73.12,1.34.55,1.82s1.03.73,1.79.75c.82.02,1.51-.21,2.05-.69.55-.48.86-1.12.94-1.92.07-.7-.1-1.26-.49-1.67-.39-.42-1-.65-1.81-.71h-.17s-1.03-.02-1.03-.02l.12-.85Z"
            />
            <path
              className="cls-2"
              d="M754.05,514.77c-.51-.01-.99-.12-1.44-.32-.45-.21-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93s.71,1.44.72,2.54c0,.34-.01.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM757.07,510.02l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15s.84.4,1.37.42c.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <g>
            <path
              className="cls-2"
              d="M162.67,372.08l-.1.85h-.26c-1.3.03-2.4.45-3.29,1.26s-1.55,1.98-1.97,3.51c.83-.97,1.85-1.44,3.06-1.42.63.01,1.17.18,1.62.52s.78.8.98,1.4.28,1.25.21,1.95c-.05.56-.19,1.1-.41,1.62s-.51.98-.87,1.36c-.74.8-1.65,1.19-2.7,1.17-.91-.03-1.64-.35-2.2-.96s-.88-1.4-.96-2.38c-.05-.5.02-1.29.2-2.37.34-2.04,1.08-3.64,2.21-4.79s2.59-1.73,4.35-1.73h.13ZM159.91,377.14c-.65-.01-1.27.17-1.88.55-.6.38-.99.85-1.15,1.4l-.11.85c-.07.63-.03,1.21.11,1.74.15.53.4.96.76,1.28s.81.49,1.34.5c.52.01.99-.12,1.42-.41.42-.28.77-.67,1.04-1.18s.44-1.07.51-1.69c.09-.88-.05-1.61-.41-2.18-.36-.55-.91-.85-1.63-.86Z"
            />
            <path
              className="cls-2"
              d="M168.06,384.3c-.51-.01-.99-.12-1.44-.32s-.82-.53-1.1-.96l-.82,4.53h-.97l2.13-12.3h.9l-.23,1.26c.77-.98,1.74-1.45,2.9-1.42.83.02,1.48.33,1.95.93.46.6.71,1.44.72,2.54,0,.34,0,.68-.05,1l-.03.23c-.14.96-.39,1.78-.77,2.48-.38.69-.84,1.21-1.38,1.56-.54.33-1.14.49-1.81.47ZM171.08,379.55l.05-.78c.02-.88-.14-1.56-.48-2.06-.33-.5-.83-.76-1.49-.78-.57-.01-1.1.13-1.56.42-.47.29-.86.72-1.19,1.28l-.73,4.27c.17.5.45.89.83,1.15.38.26.84.4,1.37.42.79.03,1.47-.27,2.02-.89.55-.62.92-1.49,1.11-2.6l.07-.43Z"
            />
          </g>
          <path
            className="cls-2"
            d="M260.13,103.05c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h.01Z"
          />
          <path
            className="cls-2"
            d="M521.23,97.7c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.9,1.88-1.35,3.15-1.35ZM520.73,103.81c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.38-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M531.35,97.7c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.9,1.89-1.35,3.15-1.35ZM530.85,103.81c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.38-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M596.52,97.7c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.83-.9,1.88-1.35,3.15-1.35ZM596.02,103.81c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.26-.38-.59-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M576.46,149.23h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.12-1.42,4.42-7.91h2.34v7.51ZM571.77,149.23h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M527.14,43.07h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.12-1.42,4.42-7.91h2.34v7.51ZM522.45,43.07h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M661.56,79.14h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.12-1.42,4.42-7.91h2.34v7.51ZM656.86,79.14h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M649.58,71.51c.42,0,.8.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.88-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89c-.68.75-1.57,1.12-2.66,1.12s-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.85-.91,1.89-1.35,3.16-1.35ZM649.08,77.61c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.98-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.24-.37-.58-.57-.99-.57Z"
          />
          <path
            className="cls-2"
            d="M716.27,72.51c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.88-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1.01-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89c-.68.75-1.57,1.12-2.66,1.12s-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.91,1.88-1.35,3.15-1.35ZM715.77,78.61c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.37-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M716.27,107.48c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.9,1.88-1.35,3.15-1.35ZM715.77,113.59c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.38-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M599.79,34.76c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27c-.72-.85-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.83-.9,1.88-1.35,3.15-1.35ZM599.29,40.86c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.26-.37-.59-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M266.2,101.08c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8-.38.53-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09.51.73.76,1.68.76,2.87s-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.91,1.88-1.35,3.15-1.35ZM265.7,107.18c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15s.66.76,1.13.76c.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.37-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M262.2,226.53c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.83-.9,1.88-1.35,3.15-1.35ZM261.7,232.64c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15s.66.76,1.13.76c.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.38-.59-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M341.79,346.24c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27c-.72-.85-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.9,1.88-1.35,3.15-1.35ZM341.29,352.34c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.37-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M623.86,475.82c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27c-.72-.85-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.9,1.89-1.35,3.15-1.35ZM623.36,481.93c-.36,0-.67.06-.93.19s-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.38-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M36.25,306.08c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27c-.72-.85-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.83-.9,1.88-1.35,3.15-1.35ZM35.75,312.19c-.36,0-.67.06-.93.19-.26.13-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.26-.37-.59-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M717.28,143.56c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.88-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1.01-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89c-.68.75-1.57,1.12-2.66,1.12s-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.83-.9,1.88-1.35,3.15-1.35ZM716.77,149.66c-.36,0-.67.06-.93.19s-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.37-.58-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M712.25,191.95l.62-6.67h6.13v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.12.91.38,1.2s.58.43.99.43c.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.09-.12Z"
          />
          <path
            className="cls-2"
            d="M602.22,104.46l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.12.91.38,1.2s.58.43.99.43c.48,0,.82-.19,1.03-.56.21-.37.32-.91.32-1.63s-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26-.2.17-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M712.25,225.92l.62-6.67h6.13v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.09-.12Z"
          />
          <path
            className="cls-2"
            d="M558.8,41.52l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M188.57,109.34l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43.3-.12.63-.18.97-.19,1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88s-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26-.2.17-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M32.37,347.91l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88s-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M304,233.29l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43.3-.12.63-.18.97-.19,1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88s-1.53,1.12-2.78,1.12c-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26-.2.17-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M398.88,353.22l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43.3-.12.63-.18.97-.19,1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88s-1.53,1.12-2.78,1.12c-1.01,0-1.87-.29-2.59-.88s-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26-.2.17-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M681.04,472.56l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M750.45,487.55l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M713.25,262l.62-6.67h6.13v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.12.91.38,1.2s.58.43.99.43c.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.09-.12Z"
          />
          <path
            className="cls-2"
            d="M737.29,319.15c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.58-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.57.24,1.02.59,1.33,1.08s.47,1.06.47,1.75c0,1.09-.37,1.94-1.1,2.57s-1.67.93-2.8.93c-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48.29-.32.43-.74.43-1.24,0-.65-.13-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.25Z"
          />
          <path
            className="cls-2"
            d="M725.14,189.73c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.58-.46-1.02-.46c-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.57.24,1.02.59,1.33,1.08s.47,1.06.47,1.75c0,1.09-.37,1.94-1.1,2.57s-1.67.93-2.8.93c-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48.29-.32.43-.74.43-1.24,0-.65-.13-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.25Z"
          />
          <path
            className="cls-2"
            d="M684.24,310.56c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M741.33,387.73c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M751.55,388.02c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M741.33,432.29c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.32.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88s1.01,1.42,1.01,2.5c0,.55-.15,1.06-.44,1.52s-.7.82-1.21,1.08c.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M665.34,377.13c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.32.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88s1.01,1.42,1.01,2.5c0,.55-.15,1.06-.44,1.52s-.7.82-1.21,1.08c.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M631.41,311.94c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.32.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88s1.01,1.42,1.01,2.5c0,.55-.15,1.06-.44,1.52s-.7.82-1.21,1.08c.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M585.15,431.18c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M725.42,77.6c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.77h1.24Z"
          />
          <path
            className="cls-2"
            d="M260.76,43.91c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.04c-.03-.94.3-1.71.99-2.31.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42s-.66-.46-1.18-.46h-1.21v-1.76h1.24v-.02Z"
          />
          <path
            className="cls-2"
            d="M397.74,231.37c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42s-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M503.16,289.28c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.59-.46-1.02-.46-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M33.31,227.39c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4-.24.27-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.86.39,1.17.26.31.59.47.99.47.47,0,.85-.16,1.14-.48.29-.32.44-.74.44-1.24,0-.65-.14-1.12-.41-1.42s-.66-.46-1.18-.46h-1.18v-1.76h1.21Z"
          />
          <path
            className="cls-2"
            d="M521.54,473.22c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14s-.59-.46-1.02-.46c-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.32.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88s1.01,1.42,1.01,2.5c0,.55-.15,1.06-.44,1.52s-.7.82-1.21,1.08c.58.24,1.02.59,1.33,1.08.32.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57-.73.62-1.67.93-2.8.93-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48s.44-.74.44-1.24c0-.65-.14-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.24Z"
          />
          <path
            className="cls-2"
            d="M613.05,480.95c.47,0,.82-.15,1.06-.46.24-.3.35-.72.35-1.26,0-.45-.12-.83-.37-1.14-.25-.31-.58-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.31s1.54-.9,2.54-.9c1.13,0,2.04.29,2.71.88.68.59,1.01,1.42,1.01,2.5,0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.57.24,1.02.59,1.33,1.08.31.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57s-1.67.93-2.8.93c-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48.29-.32.43-.74.43-1.24,0-.65-.13-1.12-.41-1.43-.27-.3-.66-.46-1.18-.46h-1.21v-1.76h1.25Z"
          />
          <path
            className="cls-2"
            d="M675.21,470.84c.47,0,.82-.15,1.06-.46s.35-.72.35-1.26c0-.45-.12-.83-.37-1.14-.25-.31-.58-.46-1.02-.46-.36,0-.65.13-.89.4s-.36.62-.36,1.05h-2.27l-.02-.05c-.03-.94.3-1.71.99-2.32.69-.6,1.54-.9,2.54-.9,1.13,0,2.04.29,2.71.88s1.01,1.42,1.01,2.5c0,.55-.15,1.06-.44,1.52-.3.46-.7.82-1.21,1.08.57.24,1.02.59,1.33,1.08.31.48.47,1.06.47,1.75,0,1.09-.37,1.94-1.1,2.57s-1.67.93-2.8.93c-.99,0-1.86-.29-2.59-.88-.74-.59-1.09-1.43-1.07-2.52l.02-.05h2.26c0,.46.13.85.39,1.17s.59.47.99.47c.47,0,.85-.16,1.14-.48s.43-.74.43-1.24c0-.65-.13-1.12-.41-1.42-.27-.3-.66-.46-1.18-.46h-1.17v-1.76h1.21Z"
          />
          <path
            className="cls-2"
            d="M748.34,483.63c0,.59-.14,1.12-.42,1.57s-.66.81-1.15,1.07c.6.27,1.07.65,1.42,1.15s.52,1.07.52,1.72c0,1.11-.35,1.97-1.05,2.59-.7.61-1.6.92-2.7.92s-2.08-.31-2.81-.92c-.73-.62-1.1-1.48-1.1-2.58,0-.65.18-1.22.54-1.72s.85-.88,1.47-1.15c-.51-.26-.92-.61-1.21-1.07s-.44-.98-.44-1.57c0-1.05.33-1.86.98-2.43s1.5-.85,2.55-.85,1.82.28,2.45.85c.63.55.95,1.36.95,2.42ZM746.38,489.04c0-.54-.14-.99-.41-1.33s-.62-.52-1.02-.52c-.45,0-.83.17-1.12.52-.29.34-.44.79-.44,1.33s.15.99.44,1.32c.3.33.67.49,1.13.49.41,0,.75-.16,1.02-.49.27-.33.4-.77.4-1.32ZM746.01,483.73c0-.48-.1-.86-.29-1.14-.19-.28-.45-.42-.78-.42-.37,0-.67.14-.88.41s-.32.66-.32,1.16.11.9.33,1.2c.22.3.51.45.88.45.32,0,.58-.15.77-.44.2-.31.29-.71.29-1.22Z"
          />
          <path
            className="cls-2"
            d="M649.58,142.56c.42,0,.81.04,1.15.13s.7.21,1.06.38l-.4,1.72c-.32-.13-.61-.22-.87-.28s-.57-.09-.92-.09c-.61,0-1.11.27-1.49.8s-.57,1.29-.57,2.26v.36c.28-.3.61-.53,1-.7.39-.16.83-.25,1.32-.25.92,0,1.63.36,2.14,1.09s.76,1.68.76,2.87-.34,2.15-1.02,2.89-1.57,1.12-2.66,1.12-2.07-.42-2.79-1.27-1.08-2.01-1.08-3.48v-2.59c0-1.51.41-2.71,1.22-3.61.84-.91,1.88-1.35,3.15-1.35ZM649.08,148.66c-.36,0-.67.06-.93.19s-.46.31-.61.54v.74c0,.92.14,1.64.42,2.15.28.51.66.76,1.13.76.41,0,.74-.21.99-.63s.37-.95.37-1.58c0-.68-.12-1.21-.37-1.6-.25-.37-.59-.57-1-.57Z"
          />
          <path
            className="cls-2"
            d="M645.56,190.84l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.13.91.38,1.2.25.28.58.43.99.43.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69c-.24-.41-.59-.62-1.07-.62-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M645.56,262l.62-6.67h6.12v1.86h-4.22l-.29,2.9c.2-.17.46-.31.76-.43s.63-.18.97-.19c1.01-.02,1.78.33,2.33,1.03.54.7.82,1.68.82,2.95,0,1.17-.3,2.13-.9,2.88-.6.75-1.53,1.12-2.78,1.12-1.01,0-1.87-.29-2.59-.88-.72-.59-1.07-1.38-1.04-2.4l.02-.04,2.25-.12c0,.51.12.91.38,1.2s.58.43.99.43c.48,0,.82-.19,1.03-.56s.32-.91.32-1.63-.12-1.27-.35-1.69-.59-.62-1.07-.62c-.38,0-.67.09-.87.26s-.34.42-.42.72l-2.08-.12Z"
          />
          <path
            className="cls-2"
            d="M186.11,104.57c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h0Z"
          />
          <path
            className="cls-2"
            d="M212.43,149.04c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h0Z"
          />
          <path
            className="cls-2"
            d="M144.52,308.07c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h0Z"
          />
          <path
            className="cls-2"
            d="M534.86,470.08c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h.01Z"
          />
          <path
            className="cls-2"
            d="M34.54,73.49c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h.01Z"
          />
          <path
            className="cls-2"
            d="M89.6,73.49c-1.19,1.47-2,2.85-2.43,4.14s-.64,2.8-.64,4.54v1.46h-2.33v-1.46c0-1.68.27-3.22.81-4.61s1.32-2.75,2.35-4.07h-5.42v-1.81h7.65v1.81h.01Z"
          />
          <path
            className="cls-2"
            d="M201,154.73h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.33v7.51ZM196.31,154.73h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M23.94,232.75c.49,0,.9-.22,1.21-.67.32-.45.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.76s-.66.25-1.02.25c-.99,0-1.77-.36-2.34-1.09s-.85-1.7-.85-2.93.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25-.76.81-1.72,1.21-2.88,1.21-.37,0-.76-.04-1.16-.14s-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.29.07.62.1.98.1ZM24.21,228.72c.31,0,.59-.08.83-.23s.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.22.44.54.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M50.47,276.49c.49,0,.9-.22,1.21-.67s.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.75-.32.17-.66.25-1.02.25-.99,0-1.77-.36-2.34-1.09s-.85-1.7-.85-2.93.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25-.76.81-1.72,1.21-2.88,1.21-.37,0-.76-.04-1.16-.13s-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.28.06.61.1.98.1ZM50.73,272.45c.31,0,.59-.08.83-.23s.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.23.44.55.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M98.73,276.49c.49,0,.9-.22,1.21-.67s.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.75-.32.17-.66.25-1.02.25-.99,0-1.77-.36-2.34-1.09s-.85-1.7-.85-2.93.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25-.76.81-1.72,1.21-2.88,1.21-.37,0-.76-.04-1.16-.13s-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.29.06.62.1.98.1ZM99,272.45c.31,0,.59-.08.83-.23s.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.22.44.55.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M143.14,276.49c.49,0,.9-.22,1.21-.67.32-.45.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.76s-.66.25-1.02.25c-.99,0-1.77-.36-2.34-1.09-.57-.72-.85-1.7-.85-2.93s.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25s-1.72,1.21-2.88,1.21c-.37,0-.76-.04-1.16-.14-.4-.09-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.29.06.62.1.98.1ZM143.41,272.45c.31,0,.59-.08.83-.23.24-.15.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.22.44.54.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M674.2,382.49c.49,0,.9-.22,1.21-.67.32-.45.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.76s-.66.25-1.02.25c-.99,0-1.77-.36-2.34-1.09-.57-.72-.85-1.7-.85-2.93s.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25s-1.72,1.21-2.88,1.21c-.37,0-.76-.04-1.16-.14-.4-.09-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.29.07.62.1.98.1ZM674.47,378.46c.31,0,.59-.08.83-.23.24-.15.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.22.44.54.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M109.59,358.74c.49,0,.9-.22,1.21-.67.32-.45.48-1.06.48-1.85v-.81c-.24.33-.51.59-.83.76s-.66.25-1.02.25c-.99,0-1.77-.36-2.34-1.09-.57-.72-.85-1.7-.85-2.93s.34-2.17,1.03-2.96c.68-.79,1.56-1.19,2.63-1.19s1.98.41,2.68,1.22c.69.81,1.04,1.94,1.04,3.38v3.23c0,1.36-.38,2.45-1.14,3.25s-1.72,1.21-2.88,1.21c-.37,0-.76-.04-1.16-.14-.4-.09-.77-.22-1.12-.38l.39-1.68c.31.14.61.24.9.3.28.07.61.1.98.1ZM109.85,354.71c.31,0,.59-.08.83-.23.24-.15.44-.36.6-.62v-1.43c0-.77-.13-1.36-.38-1.77-.25-.4-.59-.61-1-.61s-.73.23-.98.69-.37,1.01-.37,1.65c0,.69.11,1.25.33,1.67.23.44.55.65.97.65Z"
          />
          <path
            className="cls-2"
            d="M255.11,234.06h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h0ZM250.41,234.06h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M300.67,234.06h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM295.97,234.06h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M345.91,233.92h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM341.22,233.92h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M355.33,233.92h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM350.63,233.92h2.37v-4.32h-.05s-.21.47-.21.47l-2.11,3.85Z"
          />
          <path
            className="cls-2"
            d="M467.09,354.13h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM462.39,354.13h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M754.12,434.83h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM749.42,434.83h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M37.74,415.78h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h0ZM33.04,415.78h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M391.37,233.92h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM386.67,233.92h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M436.99,233.69h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.12-1.42,4.42-7.91h2.34v7.51h0ZM432.3,233.69h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M497.36,296.36h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M429.61,296.36h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M439.44,296.08h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M750.16,326.23h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46-.43,0-.77.19-1,.58s-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M729.04,231.37h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46-.43,0-.77.19-1,.58s-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01s-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M729.04,119.48h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46-.43,0-.77.19-1,.58s-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01s-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M447.91,238.08h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64s.31-.88.31-1.26c0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.62,2.36h4.32v1.8h-.02Z"
          />
          <path
            className="cls-2"
            d="M362.92,296.36h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24s-.51-.46-.92-.46-.77.19-1,.58c-.23.39-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.63,2.36h4.32v1.8h-.01Z"
          />
          <path
            className="cls-2"
            d="M293.98,296.36h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24s-.51-.46-.92-.46-.77.19-1,.58c-.23.39-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.63,2.36h4.32v1.8h-.01Z"
          />
          <path
            className="cls-2"
            d="M64.11,278.21h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24s-.51-.46-.92-.46-.77.19-1,.58c-.23.39-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71.67-.74,1.56-1.11,2.68-1.11s2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.63,2.36h4.32v1.8h-.01Z"
          />
          <path
            className="cls-2"
            d="M39.61,452.36h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24s-.51-.46-.92-.46-.77.19-1,.58c-.23.39-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71.67-.74,1.56-1.11,2.68-1.11s2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.46l-1.63,2.36h4.32v1.8h-.01Z"
          />
          <path
            className="cls-2"
            d="M134.21,318.01h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h0Z"
          />
          <path
            className="cls-2"
            d="M109.59,278.21h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M491.08,238.74h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M369.02,296.36h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M695.06,317.64h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M727.52,266.92h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M727.52,155.32h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M29.25,318.01h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M188.12,51.87h-2.32v-9.94l-2.18.06v-1.79l4.51-.46v12.13h0Z"
          />
          <path
            className="cls-2"
            d="M96.65,83.72h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M29.25,353.36h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <g>
            <path
              className="cls-2"
              d="M26.11,386.22h-2.07v-8.87l-1.95.05v-1.59l4.02-.41v10.82Z"
            />
            <path
              className="cls-2"
              d="M29.47,381.51l.56-5.95h5.46v1.66h-3.76l-.26,2.59c.18-.15.41-.28.68-.38s.56-.16.87-.17c.9-.01,1.59.29,2.08.92s.73,1.5.73,2.63c0,1.04-.27,1.9-.8,2.57s-1.36,1-2.48,1c-.9,0-1.67-.26-2.31-.78s-.95-1.24-.93-2.14v-.04s2.02-.1,2.02-.1c0,.46.11.82.34,1.07s.52.38.89.38c.42,0,.73-.17.92-.5s.28-.82.28-1.45-.1-1.14-.31-1.5c-.21-.37-.53-.55-.95-.55-.34,0-.6.08-.78.23s-.31.37-.37.64l-1.88-.13Z"
            />
            <path
              className="cls-2"
              d="M43.41,382.46c0,1.2-.23,2.15-.68,2.86s-1.11,1.06-1.98,1.06c-.38,0-.71-.09-1-.27-.28-.18-.52-.44-.73-.77l-.14.89h-1.77v-11.43h2.06v4.2c.19-.27.42-.48.67-.63s.55-.22.89-.22c.87,0,1.54.38,1.99,1.13s.68,1.76.68,3.02v.16h0ZM41.35,382.3c0-.8-.08-1.42-.24-1.86s-.46-.67-.89-.67c-.24,0-.45.06-.62.18s-.31.29-.41.51v3.68c.1.2.24.35.41.45s.38.15.63.15c.43,0,.73-.2.89-.59s.23-.96.23-1.71v-.14Z"
            />
          </g>
          <path
            className="cls-2"
            d="M29.25,420.57h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M29.25,452.78h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M68.99,443.93c0,.59-.14,1.12-.42,1.57-.28.46-.66.81-1.15,1.07.6.27,1.07.65,1.42,1.15s.52,1.07.52,1.72c0,1.11-.35,1.97-1.05,2.59-.7.61-1.6.92-2.7.92s-2.07-.31-2.81-.92c-.73-.62-1.1-1.48-1.1-2.58,0-.64.18-1.22.54-1.72s.85-.89,1.47-1.15c-.51-.26-.92-.61-1.21-1.07-.29-.46-.44-.98-.44-1.57,0-1.05.33-1.86.98-2.43.65-.57,1.5-.85,2.55-.85s1.82.28,2.45.85c.63.56.95,1.37.95,2.42ZM67.03,449.34c0-.54-.14-.99-.41-1.33-.28-.35-.62-.52-1.02-.52-.45,0-.83.17-1.12.52-.3.34-.44.79-.44,1.33s.15.99.44,1.32c.3.33.67.49,1.13.49.41,0,.75-.16,1.02-.49.27-.33.4-.76.4-1.32ZM66.66,444.04c0-.48-.1-.86-.29-1.14-.19-.28-.45-.42-.78-.42-.37,0-.67.14-.88.41s-.32.66-.32,1.16.11.9.33,1.2c.22.3.51.45.88.45.32,0,.58-.15.77-.44.2-.32.29-.72.29-1.22Z"
          />
          <path
            className="cls-2"
            d="M597.88,429.37c0,.59-.14,1.12-.42,1.57-.28.46-.66.81-1.15,1.07.6.27,1.07.65,1.42,1.15s.52,1.07.52,1.72c0,1.11-.35,1.97-1.05,2.59-.7.61-1.6.92-2.7.92s-2.07-.31-2.81-.92c-.73-.62-1.1-1.48-1.1-2.58,0-.64.18-1.22.54-1.72s.85-.89,1.47-1.15c-.51-.26-.92-.61-1.21-1.07-.29-.46-.44-.98-.44-1.57,0-1.05.33-1.86.98-2.43.65-.57,1.5-.85,2.55-.85s1.82.28,2.45.85c.64.56.95,1.37.95,2.42ZM595.93,434.78c0-.54-.14-.99-.41-1.33-.28-.35-.62-.52-1.02-.52-.45,0-.83.17-1.12.52-.3.34-.44.79-.44,1.33s.15.99.44,1.32c.3.33.67.49,1.13.49.41,0,.75-.16,1.02-.49.26-.32.4-.76.4-1.32ZM595.56,429.48c0-.48-.1-.86-.29-1.14-.19-.28-.45-.42-.78-.42-.37,0-.67.14-.88.41s-.32.66-.32,1.16.11.9.33,1.2c.22.3.51.45.88.45.32,0,.58-.15.77-.44.19-.32.29-.72.29-1.22Z"
          />
          <path
            className="cls-2"
            d="M586.82,144.83c0,.59-.14,1.12-.42,1.57-.28.46-.66.81-1.15,1.07.6.27,1.07.65,1.42,1.15s.52,1.07.52,1.72c0,1.11-.35,1.97-1.05,2.59-.7.61-1.6.92-2.7.92s-2.07-.31-2.81-.92c-.73-.62-1.1-1.48-1.1-2.58,0-.64.18-1.22.54-1.72s.85-.89,1.47-1.15c-.51-.26-.92-.61-1.21-1.07-.29-.46-.44-.98-.44-1.57,0-1.05.33-1.86.98-2.43.65-.57,1.5-.85,2.55-.85s1.82.28,2.45.85c.64.55.95,1.37.95,2.42ZM584.87,150.24c0-.54-.14-.99-.41-1.33-.28-.35-.62-.52-1.02-.52-.45,0-.83.17-1.12.52-.3.34-.44.79-.44,1.33s.15.99.44,1.32c.3.33.67.49,1.13.49.41,0,.75-.16,1.02-.49.27-.33.4-.77.4-1.32ZM584.5,144.94c0-.48-.1-.86-.29-1.14s-.45-.42-.78-.42c-.37,0-.67.14-.88.41s-.32.66-.32,1.16.11.9.33,1.2c.22.3.51.45.88.45.32,0,.58-.15.77-.44.2-.32.29-.72.29-1.22Z"
          />
          <path
            className="cls-2"
            d="M171.38,351.52c0,.59-.14,1.12-.42,1.57-.28.46-.66.81-1.15,1.07.6.27,1.07.65,1.42,1.15.35.5.52,1.07.52,1.72,0,1.11-.35,1.97-1.05,2.59-.7.61-1.6.92-2.7.92s-2.07-.31-2.81-.92c-.73-.62-1.1-1.48-1.1-2.58,0-.64.18-1.22.54-1.72s.85-.89,1.47-1.15c-.51-.26-.92-.61-1.21-1.07-.29-.46-.44-.98-.44-1.57,0-1.05.33-1.86.98-2.43s1.5-.85,2.55-.85,1.82.28,2.45.85c.64.56.95,1.37.95,2.42ZM169.43,356.93c0-.54-.14-.99-.41-1.33-.28-.35-.62-.52-1.02-.52-.45,0-.83.17-1.12.52-.3.34-.44.79-.44,1.33s.15.99.44,1.32c.3.33.67.49,1.13.49.41,0,.75-.16,1.02-.49.26-.32.4-.76.4-1.32ZM169.06,351.63c0-.48-.1-.86-.29-1.14-.19-.28-.45-.42-.78-.42-.37,0-.67.14-.88.41s-.32.66-.32,1.16.11.9.33,1.2c.22.3.51.45.88.45.32,0,.58-.15.77-.44.19-.32.29-.72.29-1.22Z"
          />
          <path
            className="cls-2"
            d="M107.69,452.78h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M114.93,452.87h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M145.2,452.78h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h0Z"
          />
          <path
            className="cls-2"
            d="M161.5,360.47h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h0Z"
          />
          <path
            className="cls-2"
            d="M103.9,360.47h-2.32v-9.94l-2.18.06v-1.78l4.51-.46v12.12h-.01Z"
          />
          <path
            className="cls-2"
            d="M334.91,358.48h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24s-.51-.46-.92-.46-.77.19-1,.58c-.23.39-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.45l-1.63,2.36h4.32v1.81h-.01Z"
          />
          <path
            className="cls-2"
            d="M397.06,358.48h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.45l-1.62,2.36h4.32v1.81h-.02Z"
          />
          <path
            className="cls-2"
            d="M227.69,51.87h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71.67-.74,1.56-1.11,2.68-1.11s2.01.31,2.63.94c.62.63.93,1.48.93,2.56,0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.45l-1.62,2.36h4.32v1.81h-.02Z"
          />
          <path
            className="cls-2"
            d="M458.7,358.48h-7.32v-1.54l3.31-4.18c.47-.63.81-1.18,1.01-1.64.21-.46.31-.88.31-1.26,0-.52-.1-.93-.3-1.24-.2-.3-.51-.46-.92-.46s-.77.19-1,.58-.35.88-.35,1.48h-2.27l-.02-.05c-.02-1.07.3-1.97.97-2.71s1.56-1.11,2.68-1.11,2.01.31,2.63.94.93,1.48.93,2.56c0,.73-.18,1.4-.54,2.01-.36.61-.96,1.43-1.8,2.45l-1.62,2.36h4.32v1.81h-.02Z"
          />
          <path
            className="cls-2"
            d="M483.05,234.21h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.12-1.42,4.42-7.91h2.34v7.51h0ZM478.35,234.21h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M529.03,234.21h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM524.33,234.21h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <path
            className="cls-2"
            d="M661.44,191.76h1.14v1.82h-1.14v2.63h-2.33v-2.63h-4.31l-.11-1.42,4.42-7.91h2.34v7.51h-.01ZM656.74,191.76h2.36v-4.32h-.05s-.21.47-.21.47l-2.1,3.85Z"
          />
          <rect
            className="cls-1"
            x="609.13"
            y="282.69"
            width="132.13"
            height="1"
          />
          <rect
            className="cls-1"
            x="630.7"
            y="166.6"
            width="110.59"
            height="1"
            transform="translate(-1.6 6.71) rotate(-.56)"
          />
          <polygon
            className="cls-2"
            points="8.62 175.19 101.34 175.19 90.47 187.07 8.62 187.07 8.62 175.19"
          />
          <g>
            <path
              className="cls-2"
              d="M344.4,507.91h-11.04l-1.49,8.53h12.91l-.67,3.85h-17.61l4.8-27.73h17.5l-.69,3.88h-12.78l-1.33,7.66h11.06l-.66,3.81Z"
            />
            <path
              className="cls-2"
              d="M357.3,499.66l-.46,2.42c1.76-1.9,3.8-2.83,6.11-2.78,1.93.04,3.36.73,4.28,2.07.93,1.34,1.27,3.21,1.03,5.61l-2.23,13.31h-4.51l2.25-13.37c.06-.55.07-1.05.02-1.52-.17-1.51-1.07-2.29-2.7-2.32-1.71-.03-3.22.82-4.51,2.53l-2.57,14.68h-4.51l3.56-20.6,4.24-.03Z"
            />
            <path
              className="cls-2"
              d="M382.81,494.67l-.88,5.01h3.52l-.59,3.43h-3.54l-1.94,11.54c-.04.32-.04.6-.02.86.08.83.58,1.26,1.52,1.29.47,0,1-.06,1.6-.17l-.3,3.62c-.94.27-1.89.4-2.84.4-1.57-.03-2.75-.56-3.53-1.61s-1.09-2.43-.92-4.16l1.92-11.77h-3.29l.61-3.43h3.28l.88-5.01h4.52Z"
            />
            <path
              className="cls-2"
              d="M400.87,503.91c-.58-.13-1.17-.19-1.77-.19-1.98-.05-3.55.77-4.72,2.46l-2.48,14.11h-4.51l3.56-20.6,4.29-.02-.46,2.34c1.41-1.87,3.03-2.78,4.88-2.74.42,0,1,.1,1.73.29l-.52,4.35Z"
            />
            <path
              className="cls-2"
              d="M411.75,520.67c-1.79-.04-3.34-.49-4.66-1.35-1.31-.86-2.29-2.04-2.91-3.54-.63-1.5-.87-3.17-.71-5.01l.06-.78c.23-2.09.84-3.98,1.84-5.65,1-1.67,2.24-2.94,3.72-3.8,1.49-.86,3.1-1.28,4.84-1.24,2.58.05,4.51,1.01,5.8,2.88,1.29,1.87,1.77,4.3,1.46,7.3l-.27,1.98h-12.97c-.11,1.63.22,2.95.99,3.95s1.84,1.51,3.2,1.54c1.94.06,3.73-.78,5.37-2.53l2.29,2.4c-.86,1.23-2,2.19-3.41,2.87-1.42.68-2.97,1-4.64.98ZM413.67,503c-2.39-.08-4.18,1.61-5.37,5.07l8.55.02.08-.4c.09-.58.1-1.16.02-1.71-.14-.9-.5-1.61-1.07-2.13s-1.31-.81-2.21-.85ZM416.36,491.04h5.43l-6.02,5.77h-3.92s4.51-5.77,4.51-5.77Z"
            />
            <path
              className="cls-2"
              d="M434.15,520.67c-1.79-.04-3.34-.49-4.66-1.35-1.31-.86-2.29-2.04-2.91-3.54-.63-1.5-.87-3.17-.71-5.01l.06-.78c.23-2.09.84-3.98,1.84-5.65,1-1.67,2.24-2.94,3.72-3.8,1.49-.86,3.1-1.28,4.84-1.24,2.58.05,4.51,1.01,5.8,2.88,1.29,1.87,1.77,4.3,1.46,7.3l-.27,1.98h-12.97c-.11,1.63.22,2.95.99,3.95s1.84,1.51,3.2,1.54c1.94.06,3.73-.78,5.37-2.53l2.29,2.4c-.86,1.23-2,2.19-3.41,2.87-1.42.68-2.96,1-4.64.98ZM436.07,503c-2.39-.08-4.18,1.61-5.37,5.07l8.55.02.08-.4c.09-.58.1-1.16.02-1.71-.14-.9-.5-1.61-1.07-2.13s-1.31-.81-2.21-.85Z"
            />
          </g>
          <g>
            <path
              className="cls-3"
              d="M356.28,92.5c.28-1.83-.72-3.19-2.99-4.08l-1.54-.57c-2.87-1-4.91-2.19-6.11-3.56s-1.75-2.96-1.64-4.78c.1-1.57.64-2.96,1.61-4.16.97-1.2,2.25-2.12,3.84-2.77s3.28-.95,5.07-.91c1.73.04,3.26.42,4.61,1.13,1.35.72,2.37,1.71,3.08,2.99s1.04,2.73.99,4.37l-4.68-.02c.09-1.45-.23-2.57-.96-3.37-.73-.8-1.83-1.22-3.3-1.26-1.49-.03-2.74.32-3.76,1.05-1.02.72-1.62,1.7-1.8,2.93-.25,1.61.83,2.88,3.24,3.81l1.43.51c2.91,1.04,4.94,2.26,6.09,3.67,1.16,1.4,1.66,3.06,1.52,4.98-.13,1.56-.64,2.92-1.54,4.08s-2.15,2.06-3.74,2.69c-1.59.63-3.35.94-5.27.91-1.82-.01-3.46-.39-4.94-1.14s-2.61-1.79-3.4-3.11c-.79-1.33-1.15-2.83-1.09-4.52l4.7.02c-.1,1.57.29,2.79,1.16,3.64.88.85,2.13,1.29,3.77,1.31,1.52.03,2.8-.3,3.82-.97s1.63-1.62,1.83-2.87Z"
            />
            <path
              className="cls-3"
              d="M374.44,96.48c1.08.03,2.03-.29,2.87-.94.83-.65,1.36-1.51,1.59-2.56l4.27-.02c-.14,1.36-.63,2.6-1.48,3.71-.84,1.12-1.93,1.99-3.25,2.61s-2.7.91-4.13.88c-1.76-.03-3.27-.48-4.51-1.37s-2.15-2.12-2.72-3.68c-.57-1.57-.76-3.32-.57-5.25l.06-.67c.22-1.99.8-3.8,1.74-5.42.95-1.62,2.17-2.87,3.67-3.74,1.5-.88,3.16-1.29,4.99-1.24,2.23.05,4.02.79,5.35,2.22,1.33,1.43,2,3.28,2,5.57h-4.23c0-1.18-.29-2.14-.87-2.89s-1.41-1.15-2.5-1.2c-1.76-.05-3.15.7-4.17,2.25s-1.56,3.73-1.64,6.55c-.1,3.41,1.08,5.14,3.53,5.19Z"
            />
            <path
              className="cls-3"
              d="M396.69,100.16c-1.79-.04-3.34-.49-4.66-1.35s-2.29-2.04-2.91-3.54c-.63-1.5-.87-3.17-.71-5.01l.06-.78c.23-2.09.84-3.98,1.84-5.65s2.24-2.94,3.72-3.8,3.1-1.28,4.84-1.24c2.58.05,4.51,1.01,5.8,2.88s1.77,4.3,1.46,7.3l-.27,1.98h-12.97c-.12,1.63.21,2.95.99,3.95.77,1,1.84,1.51,3.2,1.54,1.94.06,3.73-.78,5.37-2.53l2.29,2.4c-.86,1.23-2,2.19-3.41,2.87-1.43.68-2.97,1-4.64.98ZM398.61,82.49c-2.39-.08-4.18,1.61-5.37,5.07l8.55.02.08-.4c.09-.58.09-1.16.02-1.71-.14-.9-.5-1.61-1.07-2.13s-1.31-.82-2.21-.85ZM401.29,70.53h5.43l-6.02,5.77h-3.92s4.51-5.77,4.51-5.77Z"
            />
            <path
              className="cls-3"
              d="M417.51,79.15l-.46,2.42c1.76-1.9,3.8-2.83,6.11-2.78,1.93.04,3.36.73,4.28,2.07.93,1.34,1.27,3.21,1.03,5.61l-2.23,13.31h-4.51l2.25-13.37c.06-.55.07-1.05.02-1.52-.17-1.51-1.07-2.29-2.71-2.32-1.71-.03-3.22.82-4.51,2.53l-2.57,14.68h-4.51l3.56-20.6,4.25-.03Z"
            />
            <path
              className="cls-3"
              d="M442.23,100.16c-1.79-.04-3.34-.49-4.66-1.35-1.31-.86-2.29-2.04-2.91-3.54-.63-1.5-.87-3.17-.71-5.01l.06-.78c.23-2.09.84-3.98,1.84-5.65s2.24-2.94,3.72-3.8c1.49-.86,3.1-1.28,4.84-1.24,2.58.05,4.51,1.01,5.8,2.88,1.29,1.87,1.77,4.3,1.46,7.3l-.27,1.98h-12.97c-.11,1.63.22,2.95.99,3.95s1.84,1.51,3.2,1.54c1.94.06,3.73-.78,5.37-2.53l2.29,2.4c-.86,1.23-2,2.19-3.41,2.87-1.42.68-2.96,1-4.64.98ZM444.15,82.49c-2.39-.08-4.18,1.61-5.37,5.07l8.55.02.08-.4c.09-.58.1-1.16.02-1.71-.14-.9-.5-1.61-1.07-2.13-.57-.54-1.31-.82-2.21-.85Z"
            />
          </g>
          <path
            className="cls-1"
            d="M608.36,346.1h-1v-12h1v12ZM608.36,322.1h-1v-12h1v12ZM608.36,298.1h-1v-12h1v12Z"
          />
          <path
            className="cls-2"
            d="M303.75,291.94c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28ZM301.43,288.46c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66c-.24.44-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86.24.44.58.65,1.02.65s.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
          <path
            className="cls-2"
            d="M156.94,273.79c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28h0ZM154.61,270.3c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66c-.24.44-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86.24.44.58.65,1.02.65s.75-.22.99-.65.36-1.05.36-1.86v-3.67h0Z"
          />
          <path
            className="cls-2"
            d="M155.31,447.94c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28h0ZM152.99,444.46c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66c-.24.44-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86.24.44.58.65,1.02.65s.75-.22.99-.65.36-1.05.36-1.86v-3.67h0Z"
          />
          <path
            className="cls-2"
            d="M644.6,314.6c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28ZM642.27,311.11c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86.24.44.58.65,1.02.65s.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
          <path
            className="cls-2"
            d="M662.79,150.11c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28ZM660.47,146.63c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86s.58.65,1.02.65.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
          <path
            className="cls-2"
            d="M78.61,448.36c0,1.41-.34,2.51-1.01,3.31-.67.8-1.56,1.2-2.66,1.2s-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3c0,0,0,3.28,0,3.28ZM76.29,444.88c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66c-.24.44-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86.24.44.58.65,1.02.65s.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
          <path
            className="cls-2"
            d="M43.36,79.15c0,1.41-.34,2.51-1.01,3.31-.67.8-1.56,1.2-2.66,1.2s-2.01-.4-2.69-1.2c-.68-.8-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3c0,0,0,3.28,0,3.28ZM41.03,75.66c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66c-.24.44-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86s.58.65,1.02.65.75-.22.99-.65.36-1.05.36-1.86v-3.67h0Z"
          />
          <path
            className="cls-2"
            d="M539.89,234.32c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28ZM537.57,230.84c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86s.58.65,1.02.65.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
          <g>
            <path
              className="cls-2"
              d="M47.26,170.16v-8.53h2.79c.93,0,1.62.19,2.09.57s.7.95.7,1.7c0,.4-.11.75-.34,1.06s-.54.54-.93.71c.46.13.83.37,1.09.74s.4.79.4,1.29c0,.77-.25,1.37-.74,1.8s-1.2.66-2.1.66h-2.96ZM48.38,165.27h1.7c.49,0,.89-.12,1.18-.37s.44-.58.44-1c0-.47-.14-.81-.41-1.02s-.69-.32-1.25-.32h-1.66v2.71ZM48.38,166.17v3.07h1.86c.52,0,.94-.14,1.24-.41s.45-.65.45-1.12c0-1.03-.56-1.54-1.68-1.54h-1.87Z"
            />
            <path
              className="cls-2"
              d="M59.86,167.93h-3.57l-.8,2.23h-1.16l3.26-8.53h.98l3.26,8.53h-1.15l-.82-2.23ZM56.63,167.01h2.9l-1.45-3.99-1.45,3.99Z"
            />
            <path
              className="cls-2"
              d="M66.53,166.71h-2v3.45h-1.13v-8.53h2.82c.96,0,1.7.22,2.22.66s.78,1.07.78,1.91c0,.53-.14.99-.43,1.39s-.69.69-1.2.88l2,3.62v.07h-1.21l-1.85-3.45ZM64.53,165.79h1.73c.56,0,1-.14,1.33-.43s.5-.68.5-1.16c0-.53-.16-.93-.47-1.21s-.77-.42-1.36-.43h-1.72v3.23h-.01Z"
            />
          </g>
          <path
            className="cls-2"
            d="M661.46,263.03c0,1.41-.34,2.51-1.01,3.31s-1.56,1.2-2.66,1.2-2.01-.4-2.69-1.2-1.01-1.9-1.01-3.31v-3.28c0-1.4.34-2.5,1.01-3.3.67-.81,1.56-1.21,2.68-1.21s2,.4,2.67,1.21,1.01,1.91,1.01,3.3v3.28ZM659.14,259.55c0-.79-.12-1.4-.37-1.84-.24-.44-.58-.66-1-.66s-.77.22-1.01.66-.36,1.05-.36,1.84v3.67c0,.8.12,1.42.36,1.86s.58.65,1.02.65.75-.22.99-.65.36-1.05.36-1.86v-3.67h.01Z"
          />
        </g>
      </svg>
    </div>
  );
};

export default Page;
