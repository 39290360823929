import { Badge, Box, ButtonBase, styled, useMediaQuery } from "@mui/material";
import { useRef, useState, Fragment } from "react";
import { apiSlice } from "store/api";

import toast from "react-hot-toast";

import { H6, Small, Tiny, FlexBox, AppAvatar } from "components";

import PopoverLayout from "./PopoverLayout";
import { getUserData, resetAuth } from "store/auth/slices";
import { useDispatch, useSelector } from "react-redux";
import { clientLogout } from "store/api";

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  cursor: "pointer",
  padding: "5px 1rem",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProfilePopover = () => {
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(getUserData);
  const [open, setOpen] = useState(false);
  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const handleLogout = () => {
    clientLogout();
    dispatch(resetAuth());
    dispatch(apiSlice.util.resetApiState());
  };

  return (
    <Fragment>
      <StyledButtonBase
        disableRipple
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            alignItems: "center",
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "4%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          {upSm && (
            <Small mx={1} color="text.secondary">
              Hi,{" "}
              <Small fontWeight="600" display="inline">
                {user.firstname + " " + user.lastname}
              </Small>
            </Small>
          )}
          <AppAvatar
            src={user?.avatar || "/static/avatar/001-man.svg"}
            sx={{
              width: 28,
              height: 28,
            }}
          />
        </Badge>
      </StyledButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center" gap={1}>
            <AppAvatar
              src={user?.avatar || "/static/avatar/001-man.svg"}
              sx={{
                width: 35,
                height: 35,
              }}
            />

            <Box>
              <H6>
                {user.firstname + " " + user.lastname + " " || "Anonymous"}
                <Small fontWeight="400" display="inline" color="text.secondary">
                  {user.role}
                </Small>
              </H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {user?.phone || "anonymous@resa.com"}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          {/* <StyledSmall onClick={() => handleMenuItem("/dashboards/profile")}>
            Profile & Account
          </StyledSmall>

          <StyledSmall onClick={() => handleMenuItem("/dashboards/account")}>
            Settings
          </StyledSmall> */}

          {/* <Divider
            sx={{
              my: 1,
            }}
          /> */}

          <StyledSmall
            onClick={() => {
              handleLogout();
              toast.error("You Logout Successfully");
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
