import { /*useEffect, useMemo,*/ useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import timezone from "dayjs/plugin/timezone";
import { Box, Button, IconButton, Typography, styled } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  CustomTable,
  FlexBox,
  H6,
  ListHeader,
  Paragraph,
  Small,
  Span,
  Tiny,
} from "components";
import ListHeader1 from "components/ListHeaders/ListHeader1";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { convertDate } from "utils/dateUtils";
import {
  useBookingsQuery,
  useUpdateBookingMutation,
  useGetAttendanceQuery,
} from "store/bookings/services";
// import AddBooking from "../booking/AddBooking";
import { useAllBookingsQuery } from "store/partnership/partnership";
import Page from "../booking/pagetable";
import { getUserData } from "store/auth/slices";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import useFormBooking from "hooks/useFormBooking";
import { date, ref } from "yup";
import AddReservation from "./AddReservation";
import { StatusDropdown } from "../Statistique/dropDownFilters";

dayjs.extend(utc);
const columnShape = [
  {
    Header: "Provenance",
    accessor: "user",
    Cell: ({ row }) => {
      const { user } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {user && user.firstname ? (
              <Tiny color="text.disabled">{user.firstname}</Tiny>
            ) : (
              <Tiny color="text.disabled">WEB</Tiny>
            )}
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Client",
    accessor: "id",
    Cell: ({ row }) => {
      const { client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{client?.fullname || "fullname"}</H6>
            <Paragraph color="text.secondary">{client?.phone}</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Tags",
    accessor: "tags",
    Cell: ({ row }) => {
      const { tags } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            {tags.map((tag) => (
              <H6 key={tag.id} color="text.primary">
                {tag.name}
              </H6>
            ))}
          </FlexBox>
        </FlexBox>
      );
    },
  },

  // {
  //   Header: "Date de création",
  //   accessor: "createdAt",
  //   Cell: ({ row }) => {
  //     const { createdAt } = row.original;
  //     return <Span>{convertDate(createdAt, "number")}</Span>;
  //   },
  // },

  {
    Header: "Note",
    accessor: "note",
  },

  {
    Header: () => {
      return <p>Arrivée</p>;
    },
    accessor: "checkin",
    Cell: ({ row }) => {
      const { checkin } = row.original;
      return <Span>{convertDate(checkin)}</Span>;
    },
  },
  {
    Header: () => {
      return <p>Création</p>;
    },
    accessor: "Création",
    Cell: ({ row }) => {
      const { createdAt } = row.original;
      return <Span>{convertDate(createdAt)}</Span>;
    },
  },

  {
    Header: "Statut",
    minWidth: " 110px",
    accessor: "status",
    Cell: ({ row }) => {
      const { status } = row.original;
      const getStatus = (val) => {
        switch (val) {
          case "canceled":
            return "Annulé";
          case "confirmed":
            return "Confirmé";
          case "installed": //to be checked
            return "Installé";
          case "alert":
            return "Alerte";
          case "gone":
            return "Reparti";
          case "noshow":
            return "No Show";
          default:
            return "En attente";
        }
      };
      return (
        <Small
          sx={{
            width: 100,
            fontSize: 14,
            color: "black",
            borderRadius: "4px",
            textAlign: "center",
            padding: ".2rem 1rem",
            backgroundColor:
              status === "canceled"
                ? "#9e9e9e"
                : status === "confirmed"
                ? "#4caf50"
                : status === "installed"
                ? "#2196f3"
                : status === "gone"
                ? "#ffffff"
                : status === "alert"
                ? "#f44336"
                : status === "noshow"
                ? "#F5EEC8"
                : "#ff9933",
          }}
        >
          {getStatus(status)}
        </Small>
      );
    },
  },
  {
    Header: "Table",
    accessor: "table",
    Cell: ({ row }) => {
      const { tables } = row.original;
      return tables.map((table) => (
        <Span key={table.id}>
          {table.tableRef}
          <br />
        </Span>
      ));
    },
  },

  // {
  //   Header: "Adultes",
  //   accessor: "adults",
  // },

  // {
  //   Header: "Enfants",
  //   accessor: "children",
  // },

  {
    Header: "Personnes",
    accessor: "persons",
    Cell: ({ row }) => {
      const { adults, children } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{adults} Adultes</H6>
            <Paragraph color="text.secondary">{children} Enfants</Paragraph>
          </FlexBox>
        </FlexBox>
      );
    },
  },
  {
    Header: "Canal",
    accessor: "canal",
    Cell: ({ row }) => {
      const { canal, client } = row.original;
      return (
        <FlexBox alignItems="center">
          <FlexBox flexDirection="column">
            <H6 color="text.primary">{canal || "googleAds"}</H6>
          </FlexBox>
        </FlexBox>
      );
    },
  },

  // {
  //   Header: "Check-out",
  //   accessor: "checkout",
  //   Cell: ({ row }) => {
  //     const { setFieldValue, handleSubmit } = useFormBooking(
  //       true,
  //       row.original,
  //       () => {}
  //     );
  //     useEffect(() => {
  //       setFieldValue("checkout", dayjs().utc(true));
  //       setFieldValue("status", "gone");
  //     }, [setFieldValue]);
  //     return (
  //       <>
  //         <Button
  //           sx={{
  //             color: "white",
  //             cursor: "pointer",
  //             padding: 1,
  //             borderRadius: 1,
  //             bgcolor: "green",
  //             "&:hover": {
  //               bgcolor: "darkgreen",
  //             },
  //           }}
  //           onClick={() => {
  //             handleSubmit();
  //           }}
  //         >
  //           <Span>Check out</Span>
  //         </Button>
  //       </>
  //     );
  //   },
  // },

  {
    Header: "Modifier",
    accessor: "edit",
    Cell: ({ row }) => {
      const [openModal, setOpenModal] = useState(false);
      // console.log("row.original", row.original.id);
      return (
        <>
          <IconButton onClick={() => setOpenModal(true)}>
            <Edit
              sx={{
                color: "text.disabled",
                fontSize: 18,
              }}
            />
          </IconButton>
          <AddReservation
            edit
            open={openModal}
            data={row.original}
            onClose={() => setOpenModal(false)}
          />
        </>
      );
    },
  },
];

const StyledRectangle = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFC664",
  color: "black",
  padding: theme.spacing(1, 2), // Adjust padding for better responsiveness
  borderRadius: 5,
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", // Ensures the component takes full width of its container
  boxSizing: "border-box", // Ensures padding is included in the width calculation

  [theme.breakpoints.down("sm")]: {
    // Adjust styles for small screens
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },

  [theme.breakpoints.up("md")]: {
    // Adjust styles for medium and larger screens
    padding: theme.spacing(1, 3), // Adjust padding as needed
  },
}));

const StyledRectangle2 = styled(Box)(({ theme }) => ({
  backgroundColor: "#1A2130",
  color: "white",
  padding: theme.spacing(1, 2), // Adjust padding for better responsiveness
  borderRadius: 5,
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%", // Ensures the component takes full width of its container
  boxSizing: "border-box", // Ensures padding is included in the width calculation

  [theme.breakpoints.down("sm")]: {
    // Adjust styles for small screens
    padding: theme.spacing(1), // Reduce padding on smaller screens
  },

  [theme.breakpoints.up("md")]: {
    // Adjust styles for medium and larger screens
    padding: theme.spacing(1, 3), // Adjust padding as needed
  },
}));

const CanalDropdown = ({ selectedCanal, setSelectedCanal }) => (
  <FormControl sx={{ minWidth: 250, maxWidth: 350 }}>
    <InputLabel>Canal</InputLabel>
    <Select
      value={selectedCanal}
      onChange={(e) => setSelectedCanal(e.target.value)}
      label="Canal"
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="website">Website</MenuItem>
      <MenuItem value="instgram">Instagram</MenuItem>
      <MenuItem value="facebook">Facebook</MenuItem>
      <MenuItem value="googleAds">Google Ads</MenuItem>
      <MenuItem value="whatsapp">WhatsApp</MenuItem>
      <MenuItem value="sms">SMS</MenuItem>
      <MenuItem value="qrCode">QR Code</MenuItem>
      <MenuItem value="receptionist">Receptionist</MenuItem>
      <MenuItem value="salespeople">Salespeople</MenuItem>
    </Select>
  </FormControl>
);

const ReservationNormal = (props) => {
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const [dateValue, setDateValue] = useState(dayjs().format("YYYY-MM-DD"));
  const [searchValue, setSearchValue] = useState("");
  const [selectedCanal, setSelectedCanal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [ids, setIds] = useState([]);
  const [partnerIds, setPartnerIds] = useState([]);

  const { data } = useBookingsQuery();
  const { data: partnersReservation } = useAllBookingsQuery();
  const { data: attendance, refetch } = useGetAttendanceQuery({
    date: dateRange.startDate,
  });
  const user = useSelector(getUserData);
  const { startDate, endDate } = dateRange;
  const startOfDay = dayjs(startDate).startOf("day").toDate();
  const endOfDay = dayjs(endDate).endOf("day");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [sum_Pax, setSum_Pax] = useState(0);

  useEffect(() => {
    if (!data || !partnersReservation) return;

    const parseDate = (dateString) => new Date(dateString);

    const filteredData = data.filter((item) => {
      const itemDate = parseDate(item.checkin);
      return (
        itemDate >= startOfDay &&
        itemDate <= endOfDay &&
        (selectedCanal ? item.canal === selectedCanal : true) &&
        (selectedStatus ? item.status === selectedStatus : true)
      );
    });
    console.log("filteredData", filteredData);
    const filteredPartnersReservation = partnersReservation.filter((item) => {
      const itemDate = parseDate(item.checkin);
      return (
        itemDate >= startOfDay &&
        itemDate <= endOfDay &&
        (selectedCanal ? item.canal === selectedCanal : true) &&
        (selectedStatus ? item.status === selectedStatus : true)
      );
    });

    const combinedTables = [...filteredData, ...filteredPartnersReservation];
    setIds(combinedTables);
    setPartnerIds(filteredPartnersReservation);
  }, [
    data,
    partnersReservation,
    startDate,
    endDate,
    dateValue,
    selectedCanal,
    selectedStatus,
  ]);

  useEffect(() => {
    refetch();
  }, [dateRange, refetch]);

  // Apply search filter to ids and partnerIds
  const filteredIds = ids.filter((item) =>
    item.client?.fullname.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filteredPartnerIds = partnerIds.filter((item) =>
    item.client?.fullname.toLowerCase().includes(searchValue.toLowerCase())
  );

  const totalClients = filteredIds.length || 0;
  const totalAdults =
    filteredIds.reduce((sum, item) => sum + (item.adults || 0), 0) || 0;
  const totalChildren =
    filteredIds.reduce((sum, item) => sum + (item.children || 0), 0) || 0;
  const totalPAX = totalAdults + totalChildren;

  const totalPartnerClients = filteredPartnerIds.length || 0;
  const totalPartnerAdults =
    filteredPartnerIds.reduce((sum, item) => sum + (item.adults || 0), 0) || 0;
  const totalPartnerChildren =
    filteredPartnerIds.reduce((sum, item) => sum + (item.children || 0), 0) ||
    0;
  const totalPartnerPAX = totalPartnerAdults + totalPartnerChildren;

  return (
    <Box pt={2} pb={4}>
      {user.role === "admin" || user.role === "marketer" ? (
        <ListHeader
          setDateValue={setDateRange}
          setSearchValue={setSearchValue}
          // handleClick={() => setOpenModal(true)}
        />
      ) : (
        <ListHeader1
          day
          setDateValue={setDateValue}
          buttonText="Ajouter une Réservation"
          setSearchValue={setSearchValue}
          handleClick={() => setOpenModal(true)}
        />
      )}
      <Box
        mt={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <Button variant="contained" onClick={() => setOpenModal(true)}>
          Ajouter une Réservation
        </Button>

        <CanalDropdown
          selectedCanal={selectedCanal}
          setSelectedCanal={setSelectedCanal}
        />
        <StatusDropdown
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </Box>

      {(user.role === "restaurateur" ||
        user.role === "admin" ||
        user.role === "marketer" ||
        user.role === "receptionist" ||
        user.role === "headsales") && (
        <Box>
          <StyledRectangle2 mt={5}>
            <Typography variant="h6">Réservations</Typography>
          </StyledRectangle2>
        </Box>
      )}
      {data && (
        <CustomTable
          data={{
            data: [...data]
              .filter((item) =>
                item.client?.fullname
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
              .filter((item) => {
                const parseDate = (dateString) => new Date(dateString);
                const itemDate = parseDate(item.checkin);
                return (
                  itemDate >= startOfDay &&
                  itemDate <= endOfDay
                );
              })
              .filter((item) => {
                return !selectedCanal || item.canal === selectedCanal;
              })
              .filter((item) => {
                return !selectedStatus || item.status === selectedStatus;
              }),
          }}
          hidePagination={true}
          columnShape={columnShape}
        />
      )}
      <AddReservation open={openModal} onClose={() => setOpenModal(false)} />
      <Box
        p={2}
        bgcolor="#433D8B"
        borderRadius={2}
        mb={2}
        mt={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          "& > *": {
            color: "#ffffff",
          },
        }}
      >
        <Typography variant="h6" sx={{ flexShrink: 0 }} mr={10}>
          Statistiques Totales
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          flexWrap="wrap"
          justifyContent="space-between"
          sx={{ flexGrow: 1, ml: 2 }} // Adding margin-left to keep elements close to the label
        >
          <Typography>Total de Réservations: {totalClients}</Typography>
          <Typography>Total Adultes: {totalAdults}</Typography>
          <Typography>Total Enfants: {totalChildren}</Typography>
          <Typography>Total de PAX: {totalPAX}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReservationNormal;
