import { Box } from "@mui/material";
import { FlexBox, H6, Small } from "components";

const ClientInfoReservation = ({ phone, email, fullname }) => {
  return (
    <Box mt={1} mb={1}>
      {fullname && (
        <FlexBox alignItems="center" mt={1.5} ml={2.5}>
          <H6 ml={1}>
            <Small color="text.secondary">Nom</Small> {fullname}
          </H6>
        </FlexBox>
      )}
      {phone && (
        <FlexBox alignItems="center" mt={1.5} ml={2.5}>
          <H6 ml={1}>
            <Small color="text.secondary">Téléphone</Small> {phone}
          </H6>
        </FlexBox>
      )}
      {email && (
        <FlexBox alignItems="center" mt={1.5} ml={2.5}>
          <H6 ml={1}>
            <Small color="text.secondary">E-mail</Small> {email}
          </H6>
        </FlexBox>
      )}
    </Box>
  );
};

export default ClientInfoReservation;
