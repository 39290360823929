import { ArrowRightAlt } from "@mui/icons-material";
import { ButtonBase } from "@mui/material";
import { FlexBetween, H5 } from "components";

const CustomFooter = () => {
  return (
    <FlexBetween gap={0.5}>
      <H5 color="text.secondary" fontSize={13}>
        Showing 1-12 of 24 result
      </H5>
      <ButtonBase
        disableRipple
        sx={{
          fontSize: 13,
          fontWeight: 600,
          color: "text.secondary",
        }}
      >
        See All
        <ArrowRightAlt />
      </ButtonBase>
    </FlexBetween>
  );
};

export default CustomFooter;
