import { apiSlice } from "store/api";
import { convertObjectToSearchParams } from "utils/apiHelpers";

export const clientApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      clients: builder.query({
        query: (searchParams) =>
          `client?${convertObjectToSearchParams(searchParams)}`,
        providesTags: ["clients"],
      }),
      searchClient: builder.query({
        query: (searchParams) =>
          `client/search?${convertObjectToSearchParams(searchParams)}`,
        providesTags: ["clients"],
      }),
      getClienByPhone: builder.query({
        query: (phone) => `client/phone/${phone}`,
        providesTags: ["clients"],
      }),
      addClient: builder.mutation({
        query: (data) => ({
          url: "/client",
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["clients"],
      }),
      updateClient: builder.mutation({
        query: ({ id, ...data }) => ({
          url: `/client/${id}`,
          method: "PATCH",
          body: { ...data },
        }),
        invalidatesTags: ["clients"],
      }),
    };
  },
});

export const {
  useClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useSearchClientQuery,
  useGetClienByPhoneQuery,
} = clientApi;
