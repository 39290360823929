import { Box, Modal, styled } from "@mui/material";
import { lightTheme } from "../constants"; // ---------------------------------------------------------
import Scrollbar from "./ScrollBar"; // Ensure this is a valid React component

// ---------------------------------------------------------
const Wrapper = styled(Box)(({ theme }) => ({
  top: "50%",
  left: "50%",
  padding: 24,
  maxWidth: 680,
  maxHeight: "80vh", // Adjusted to prevent overflow issues
  width: "100%",
  borderRadius: "4px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: lightTheme(theme)
    ? "#fff"
    : theme.palette.background.default,
  overflowY: "auto", // Handle scrolling here
}));

const AppModal = ({ children, open, handleClose, ...props }) => {
  return (
    <Modal disableEscapeKeyDown open={open} onClose={handleClose}>
      <Wrapper {...props}>{children}</Wrapper>
    </Modal>
  );
};

export default AppModal;
