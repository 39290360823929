// ** React Imports
import { Suspense } from "react";
import { Navigate } from "react-router-dom";

// ** Redux Imports
import { useSelector } from "react-redux";
import { checkIsAuth, getUserData } from "store/auth/slices";

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const user = useSelector(getUserData);
  const isAuth = useSelector(checkIsAuth);

  if (route) {
    let roles = ["admin"];
    let restrictedRoute = false;

    if (route.meta) {
      roles = route.meta.roles;
      restrictedRoute = route.meta.restricted;
    }
    if (!isAuth) {
      return <Navigate to="/login" />;
    }
    if (isAuth && restrictedRoute) {
      return <Navigate to="/" />;
    }
    if (user && !roles.includes(user.role)) {
      console.log(`User role: ${user.role}, Allowed roles: ${roles}`);

      const defaultPath = getDefaultPath(user.role);

      function getDefaultPath(role) {
        switch (role) {
          case "admin":
          case "marketer":
            return "/resa-dep-com";
          case "headsales":
          case "restaurateur":
          case "bureau":
            return "/bookings";
          case "cashier":
            return "/orders";
          default:
            return "/login"; // default path for unrecognized roles
        }
      }
      return <Navigate to={defaultPath} />;
    }
  }

  return <Suspense fallback={<div> loading...</div>}>{children}</Suspense>;
};

export default PrivateRoute;
