import { Grid } from "@mui/material";

import { useDispatch } from "react-redux";

import ClientInfoReservation from "./ClientInfoReservation";
import ClientFormReservation from "./ClientFormReservation";
import { setClient } from "store/clients/slices";

const ClientSectionReservation = ({ edit, data, handleBack, handleNext }) => {
  const dispatch = useDispatch();

  const handleCallback = (res) => {
    handleNext();
    dispatch(setClient(res));
  };

  return (
    <Grid container spacing={2}>
      {edit ? (
        data.client === undefined ? (
          <ClientInfoReservation
            fullname={data.Partner.name}
            email={data.Partner.email}
            phone={data.Partner.phone}
          />
        ) : (
          <ClientInfoReservation {...data?.client} />
        )
      ) : (
        <Grid item xs={12}>
          <ClientFormReservation onClose={handleBack} handleCallback={handleCallback} />
        </Grid>
      )}
    </Grid>
  );
};

export default ClientSectionReservation;
