import { useState } from "react";
import { useDispatch } from "react-redux";

import { LoginOutlined, ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, styled, useMediaQuery } from "@mui/material";

import MobileSidebar from "./MobileSidebar";
import MultiLevelMenu from "./MultiLevelMenu";
import { H1, FlexBox, Scrollbar, FlexBetween } from "components";

import { clientLogout } from "store/api";
import { resetAuth } from "store/auth/slices";

const TOP_HEADER_AREA = 70;
const BOTTOM_FOOTER_AREA = 80;

const SidebarWrapper = styled(Box)(({ theme, compact }) => ({
  height: "100vh",
  position: "fixed",
  width: compact ? 86 : 280,
  transition: "all .2s ease",
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background.paper,
  "&:hover": compact && {
    width: 280,
  },
}));

const NavWrapper = styled(FlexBox)(() => ({
  paddingTop: 46,
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));

const FooterWrapper = styled(Box)(() => ({
  paddingTop: 16,
  paddingLeft: 16,
  paddingRight: 16,
}));

const StyledArrow = styled(ArrowBack)(() => ({
  display: "block",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Sidebar = (props) => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar,
    setSidebarCompact,
  } = props;
  const dispatch = useDispatch();
  const [onHover, setOnHover] = useState(false);
  const downLg = useMediaQuery((theme) => theme.breakpoints.down("lg")); // Activate compact when toggle button clicked and not on hover state

  const COMPACT = sidebarCompact && !onHover ? 1 : 0; //   IF MOBILE

  const handleLogOut = () => {
    clientLogout();
    dispatch(resetAuth());
  };

  if (downLg) {
    return (
      <MobileSidebar
        sidebarCompact={!!COMPACT}
        showMobileSideBar={!!showMobileSideBar}
        setShowMobileSideBar={setShowMobileSideBar}
      />
    );
  }

  return (
    <SidebarWrapper
      compact={sidebarCompact}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => sidebarCompact && setOnHover(false)}
    >
      <FlexBetween pt={3} pr={2} pl={4} pb={1} height={TOP_HEADER_AREA}>
        {/* LOGO */}
        <FlexBox>
          {!COMPACT ? (
            // <img src="/static/logo/logo.svg" alt="logo" width={160} />
            <H1>Resa</H1>
          ) : (
            // <img src="/static/logo/logo-sm.png" alt="logo" width={30} />
            <H1>R</H1>
          )}
        </FlexBox>
        <Box mx={"auto"}></Box>

        {/* SIDEBAR COLLAPSE BUTTON */}
        <StyledIconButton
          onClick={setSidebarCompact}
          sx={{
            display: COMPACT ? "none" : "block",
          }}
        >
          <StyledArrow />
        </StyledIconButton>
      </FlexBetween>

      {/* NAVIGATION ITEMS */}
      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: "hidden",
          height: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <NavWrapper flexDirection="column" justifyContent="space-between">
          <MultiLevelMenu sidebarCompact={!!COMPACT} />
        </NavWrapper>
      </Scrollbar>

      <FooterWrapper height={BOTTOM_FOOTER_AREA}>
        {!!COMPACT ? (
          <IconButton size="small" onClick={() => {}}>
            <LoginOutlined />
          </IconButton>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={handleLogOut}
            startIcon={<LoginOutlined />}
          >
            Log out
          </Button>
        )}
      </FooterWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
