import { useMemo } from "react";
import { useTable } from "react-table";
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";

import CustomFooter from "./CustomFooter";
import CustomPagination from "./CustomPagination";

const CustomTable = (props) => {
  const { data, gotoPage, showFooter, columnShape, hidePagination } = props; // hooks

  const tableData = useMemo(() => data.data, [data.data]);
  const columns = useMemo(() => columnShape, [columnShape]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: tableData,
      manualPagination: true,
    });

  return (
    <Box
      sx={{
        overflowY: "scroll",
      }}
    >
      <Table
        {...getTableProps()}
        sx={{
          borderSpacing: "0 1rem",
          borderCollapse: "separate",
        }}
      >
        <TableHead>
  {headerGroups.map((headerGroup, headerGroupIndex) => (
    <TableRow
      {...headerGroup.getHeaderGroupProps()}
      key={headerGroupIndex} // Add a unique key here
    >
      {headerGroup.headers.map((column, columnIndex) => (
        <TableCell
          key={column.id || columnIndex} // Add a unique key here
          sx={{
            paddingY: 0,
            fontSize: 12,
            fontWeight: 600,
            borderBottom: 0,
            width: column.width,
            color: "text.secondary",
            minWidth: column.minWidth,
            maxWidth: column.maxWidth,
            "&:last-child": {
              textAlign: "center",
            },
          }}
        >
          {column.render("Header")}
        </TableCell>
      ))}
    </TableRow>
  ))}
</TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                // onClick={rowClick && rowClick(row.original)}
                sx={{
                  backgroundColor: "background.paper",
                  // cursor: rowClick ? "pointer" : "unset",
                  "& td:first-of-type": {
                    borderLeft: "0",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  },
                  "& td:last-of-type": {
                    borderRight: "0",
                    textAlign: "center",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        border: 0,
                        fontSize: 13,
                        fontWeight: 500,
                        color: "text.secondary",
                        "&:first-of-type": {
                          paddingLeft: "16px",
                        },
                        "&:last-child": {
                          paddingRight: "16px",
                        },
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {!hidePagination && (
        <CustomPagination meta={data.meta} gotoPage={gotoPage} />
      )}

      {showFooter && <CustomFooter />}
    </Box>
  );
};

export default CustomTable;
