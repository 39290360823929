import { useState } from "react";
import {
  Box,
  Grid,
  Step,
  styled,
  Stepper,
  StepLabel,
  Divider,
  Button,
} from "@mui/material";

import ReservationForm from "./ReservationForm";
import ClientSectionReservation from "./ClientSectionReservation";
import { H2, H5, AppModal, FlexBetween } from "components";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  maxWidth: 700,
  minWidth: 300,
  boxShadow: theme.shadows[2],
  overflow: "auto",
  [theme.breakpoints.down("sm")]: {
    "& .main-form": {
      height: 200,
      overflow: "auto",
    },
  },
}));

const AddReservation = ({ open, edit, data, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Informations client", "Informations de réservation"];

  const handleNext = () => {
    if (activeStep === steps.length) {
      onClose();
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onClose();
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <StyledAppModal open={open} handleClose={onClose}>
      <H2 mb={3}>
        {edit ? "Modifier la réservation" : "Ajouter une nouvelle réservation"}
      </H2>
      {edit ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider
              sx={{
                width: "100%",
              }}
            />
          </Grid>
  
          <Grid item xs={12}>
            <H5>
              Informations {data.client === undefined ? "partenaire" : "client"}
            </H5>
          </Grid>
          <Grid item xs={12}>
            <ClientSectionReservation edit={edit} data={data} />
          </Grid>
  
          <Grid item xs={12}>
            <Divider
              sx={{
                width: "100%",
              }}
            />
          </Grid>
  
          <Grid item xs={12}>
            <H5>Informations de réservation</H5>
          </Grid>
          <Grid item xs={12}>
            <ReservationForm
              edit={edit}
              data={data}
              onClose={onClose}
              handleCallback={onClose}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Box container spacing={2} mt={1}>
              <ClientSectionReservation handleBack={handleBack} handleNext={handleNext} />
            </Box>
          )}
          {activeStep === 1 && (
            <Box container spacing={2} mt={3}>
              <ReservationForm
                onClose={handleBack}
                handleCallback={handleNext}
              />
            </Box>
          )}
          {activeStep === steps.length && (
            <Box container spacing={2}>
              <H2 mt={3} mb={3}>
                Réservation ajoutée avec succès
              </H2>
              <FlexBetween justifyContent="flex-end" gap={2} marginTop={3}>
                <Button onClick={handleNext} variant="contained">
                  Finish
                </Button>
              </FlexBetween>
            </Box>
          )}
        </>
      )}
    </StyledAppModal>
  );  
};

export default AddReservation;
